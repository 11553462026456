import { graphql } from "gatsby"

export const BblOffsetImageBlockFragment = graphql`
  fragment BblOffsetImageBlock on WpBlock {
    ... on WpBblOffsetImageBlock {
      name
      attributes {
        className
        photoLeft
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblOffsetImageBlockPreview = `
    ... on BblOffsetImageBlock {
        name
        attributes {
            className
            photoLeft
        }
    }
`
