import React from "react"

export default function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.997"
      height="14.262"
      viewBox="0 0 11.997 14.262"
    >
      <path
        d="M14.722,7.066l.682-.675A.753.753,0,1,0,14.34,5.327l-.675.682a6,6,0,0,0-7.34,0l-.682-.69A.756.756,0,1,0,4.577,6.391l.69.682A5.938,5.938,0,0,0,4,10.748,6,6,0,1,0,14.722,7.066ZM10,15.246a4.5,4.5,0,1,1,4.5-4.5A4.5,4.5,0,0,1,10,15.246ZM8.5,4h3a.75.75,0,0,0,0-1.5h-3A.75.75,0,1,0,8.5,4Zm2.249,4.5a.75.75,0,1,0-1.5,0V9.915a1.125,1.125,0,1,0,1.5,0Z"
        transform="translate(-4 -2.5)"
        fill="var(--page-accent)"
      />
    </svg>
  )
}
