import bg from "../../../images/alumni-bg.png"

export default theme => ({
  // eslint-disable-line
  cardRow: {
    backgroundImage: "url(" + bg + ")",
    backgroundColor: "var(--uow-white)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    width: "calc(100% + 24px)",
    marginLeft: -12,
    marginRight: -12,
    padding: "50px 0",
    [theme.breakpoints.up("md")]: {
      padding: "130px 0 180px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100vw",
      marginLeft: "50%",
      transform: "translateX(-50vw)",
      marginRight: 0,
    },
    "& .wrapper": {
      justifyContent: "center",
      "& > .MuiTypography-root": {
        flex: "0 0 calc(83.333% - 24px)",
        maxWidth: "calc(83.333% - 24px)",
        margin: "0 auto 50px",
        fontWeight: "bold",
        [theme.breakpoints.up("md")]: {
          margin: "0 auto 88px",
        },
      },
      "& .card-link": {
        flex: "0 0 calc(83.333% - 24px)",
        maxWidth: "calc(83.333% - 24px)",
        margin: "0 auto 24px",
        [theme.breakpoints.up("md")]: {
          flex: "0 0 calc(41.666% - 24px)",
          maxWidth: "calc(41.666% - 24px)",
          margin: "0 12px",
        },
      },
    },
  },
})
