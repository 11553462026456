export default theme => ({
  // eslint-disable-line
  socialShare: {
    backgroundColor: "var(--uow-white)",
    padding: 8,
    marginLeft: "auto",
    borderTopLeftRadius: 30,
    display: "table",
    marginRight: 0,
    fontSize: 0,
    [theme.breakpoints.up("md")]: {
      borderTopLeftRadius: 40,
      position: "absolute",
      bottom: 0,
      right: 0,
      zIndex: 20,
      padding: "8px 16px",
    },
    "& a": {
      width: 38,
      height: 38,
      backgroundColor: "var(--uow-white)",
      border: "1px solid var(--uow-royal-blue)",
      borderRadius: "50%",
      fontSize: 0,
      padding: 10,
      margin: 8,
      boxSizing: "border-box",
      color: "var(--uow-royal-blue)",
      display: "inline-block",
      transition: "color .3s ease-in-out, background-color .3s ease-in-out",
      cursor: "pointer",
      "&:hover, &:focus": {
        color: "var(--uow-white)",
        backgroundColor: "var(--uow-royal-blue)",
      },
      [theme.breakpoints.up("md")]: {
        width: 48,
        height: 48,
        padding: 15,
      },
      "& svg": {
        width: 16,
        height: 16,
        display: "block",
      },
    },
  },
})
