import { graphql } from "gatsby"

export const BblImageTextColumnsBlockFragment = graphql`
  fragment BblImageTextColumnsBlock on WpBlock {
    ... on WpBblImageTextColumnsBlock {
      name
      attributes {
        className
        photoLeft
        textTop
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblImageTextColumnsBlockPreview = `
    ... on BblImageTextColumnsBlock {
        name
        attributes {
            className
            photoLeft
            textTop
        }
    }
`
