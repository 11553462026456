import React from "react"
import Wrapper from "../../Reusable/Wrapper"
import { makeStyles } from "@material-ui/styles"
import { AcfVideoBlockPreview } from "./queries"
import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const AcfVideoBlock = ({ block, attributes }) => {
  const classes = useStyles()

  if (block.blockVideo.videoId === null) {
    return null
  }
  const className = attributes.className

  return (
    <div className={`videoBlock ${className} ${classes.video}`}>
      <Wrapper>
        {block.blockVideo.platform === "youtube" && (
          <iframe
            src={`https://www.youtube.com/embed/${block.blockVideo.videoId}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        )}
        {block.blockVideo.platform === "vimeo" && (
          <iframe
            src={`https://player.vimeo.com/video/${block.blockVideo.videoId}`}
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
        )}
      </Wrapper>
    </div>
  )
}

export { AcfVideoBlockPreview }
