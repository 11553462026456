import { graphql } from "gatsby"

export const BblImageTextStaggeredBlockFragment = graphql`
  fragment BblImageTextStaggeredBlock on WpBlock {
    ... on WpBblImageTextStaggeredBlock {
      name
      attributes {
        className
        photoLeft
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblImageTextStaggeredBlockPreview = `
    ... on BblImageTextStaggeredBlock {
        name
        attributes {
            className
            photoLeft
        }
    }
`
