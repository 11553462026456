import { graphql } from "gatsby"

export const AcfThreeColumnContentBlockFragment = graphql`
  fragment AcfThreeColumnContentBlock on WpBlock {
    ... on WpAcfThreeColumnContentBlock {
      name
      attributes {
        className
      }
      blockThreeColumnContent {
        backgroundColour
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfThreeColumnContentBlockPreview = `
    ... on AcfThreeColumnContentBlock {
        name
        attributes {
            className
        }
        backgroundColour
    }
}`
