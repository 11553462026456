import React, { Component } from "react"

import Slider from "react-slick"
import LifeGrid from "../../Reusable/LifeGrid"
import Icon from "../../Reusable/Card/Icon"

import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      <div>
        <Icon />
      </div>
    </div>
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
      onClick={onClick}
    >
      <div style={{ transform: "scaleX(-1)" }}>
        <Icon />
      </div>
    </div>
  )
}

export default class MagazineSlider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nav1: null,
      nav2: null,
      slider: [],
    }
  }

  componentDidMount() {
    this.setState({
      slider: this.props.editions,
      nav1: this.slider1,
      nav2: this.slider2,
    })
  }

  render() {
    return (
      <div className="sliders">
        <Slider
          ref={slider => (this.slider2 = slider)}
          asNavFor={this.state.nav1}
          arrows={true}
          dots={false}
          infinite={true}
          autoplay={false}
          autoplaySpeed={10000}
          pauseOnFocus={false}
          pauseOnHover={false}
          className="magazines"
          centerMode={true}
          slidesToShow={3}
          slidesToScroll={1}
          responsive={[
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
        >
          {this.state.slider.map(edition => {
            return (
              <div className={`slide`} key={edition.id}>
                <a
                  href={edition.uri}
                  className={`${edition.acfPageColour.pageColour}-accent ${
                    edition.slug > 2023 ? `uncreased` : ``
                  }`}
                  style={{
                    backgroundImage:
                      edition.Editions.featuredImage.localFile !== null
                        ? "url(" +
                          edition.Editions.featuredImage.localFile.publicURL +
                          ")"
                        : "url(" +
                          edition.Editions.featuredImage.soruceUrl +
                          ")",
                  }}
                >
                  <span className="title">Edition {edition.name}</span>
                  <span className="desc">{edition.description}</span>
                </a>
              </div>
            )
          })}
        </Slider>

        <Slider
          ref={slider => (this.slider1 = slider)}
          asNavFor={this.state.nav2}
          fade={true}
          arrows={false}
          dots={false}
          className="background"
        >
          {this.state.slider.map(edition => {
            return (
              <div
                className={
                  "slide " + edition.acfPageColour.pageColour + "-accent"
                }
                key={"bg-" + edition.id}
              >
                <LifeGrid color={edition.acfPageColour.pageColour} />
              </div>
            )
          })}
        </Slider>
      </div>
    )
  }
}
