export default theme => ({
  // eslint-disable-line
  fiftyFiftyImages: {
    paddingTop: 50,
    paddingBottom: 18,
    [theme.breakpoints.up("md")]: {
      paddingTop: 130,
      paddingBottom: 70,
    },
    "& .wrapper": {
      alignItems: "flex-start",
      "& > .gatsby-image-wrapper, & > .gatsby-caption-wrapper": {
        width: "91.666%",
        marginBottom: 32,
        [theme.breakpoints.up("md")]: {
          width: "41.666%",
          marginBottom: 0,
        },
      },
      "& > div": {
        "&:nth-of-type(1)": {
          "&:before": {
            content: '""',
            backgroundColor: "var(--page-accent)",
            position: "absolute",
            width: 76,
            height: 85,
            top: -25,
            right: -24,
            clipPath:
              "polygon(0% 25px, 50% 0%, 100% 25px, 100% 100%, 50% calc(100% - 25px), 0% 100%)",
            zIndex: 1,
            [theme.breakpoints.up("md")]: {
              width: 152,
              height: 170,
              top: -50,
              clipPath:
                "polygon(0% 50px, 50% 0%, 100% 50px, 100% 100%, 50% calc(100% - 50px), 0% 100%)",
            },
          },
          "& .caption": {
            right: 0,
            left: "auto",
          },
        },
        "&:nth-of-type(2)": {
          "& .caption": {
            paddingRight: 70,
            "&:before": {
              content: '""',
              backgroundColor: "var(--page-accent)",
              position: "absolute",
              width: 120,
              height: 94,
              top: 0,
              right: -60,
              clipPath: "polygon(50% 0, 100% 50%, 50% 100%, 0 50%)",
            },
          },
        },
      },
    },
    '&[data-bottom="true"]': {
      "& .wrapper": {
        alignItems: "flex-end",
      },
    },
    '&[data-left="true"]': {
      "& .wrapper": {
        "& > .gatsby-image-wrapper, & > .gatsby-caption-wrapper": {
          marginRight: "auto",
          marginLeft: -12,
          [theme.breakpoints.up("md")]: {
            marginRight: "8.333%",
            marginLeft: 0,
            "&:nth-of-type(1)": {
              "& > div:not(.caption)": {
                width: "calc(100% + 12px)",
                maxWidth: "calc(100% + 12px)",
                marginLeft: -12,
              },
            },
          },
          [theme.breakpoints.up(1344)]: {
            "&:nth-of-type(1)": {
              "& > div:not(.caption)": {
                width: "calc(100% + (50vw - 680px))",
                maxWidth: "calc(100% + (50vw - 680px))",
                marginLeft: "calc(0px - (50vw - 680px))",
              },
            },
          },
        },
      },
    },
    '&[data-left="false"]': {
      "& .wrapper": {
        "& > .gatsby-image-wrapper, & > .gatsby-caption-wrapper": {
          marginLeft: "auto",
          marginRight: -12,
          [theme.breakpoints.up("md")]: {
            marginLeft: "8.333%",
            marginRight: 0,
            "&:nth-of-type(2)": {
              "& > div:not(.caption)": {
                width: "calc(100% + 12px)",
              },
            },
          },
          [theme.breakpoints.up(1344)]: {
            "&:nth-of-type(2)": {
              "& > div:not(.caption)": {
                width: "calc(100% + (50vw - 680px))",
              },
            },
          },
        },
      },
    },
  },
})
