export default theme => ({
  // eslint-disable-line
  introImageColumns: {
    paddingBottom: 100,
    boxShadow:
      "-300px 0 var(--uow-white), 300px 0 var(--uow-white), -600px 0 var(--uow-white), 600px 0 var(--uow-white)",
    [theme.breakpoints.up("md")]: {
      paddingBottom: 200,
    },
    "& .wrapper": {
      justifyContent: "center",
    },
  },
})
