import React from "react"
import { BblMagazineSliderBlockPreview } from "./queries"
import { makeStyles } from "@material-ui/styles"
import { useStaticQuery, graphql } from "gatsby"
import styles from "./styles"
import MagazineSlider from "./slider"

const useStyles = makeStyles(theme => styles(theme))

export const BblMagazineSliderBlock = ({ attributes }) => {
  const classes = useStyles()

  const { className } = attributes

  const data = useStaticQuery(graphql`
    query MagazineSlider {
      allWpEdition(sort: { fields: name, order: DESC }) {
        nodes {
          id
          name
          description
          uri
          slug
          acfPageColour {
            pageColour
          }
          Editions {
            featuredImage {
              sourceUrl
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className={`magazine-slider ${className} ${classes.magazineSlider} `}>
      <MagazineSlider editions={data.allWpEdition.nodes} />
    </div>
  )
}

export { BblMagazineSliderBlockPreview }
