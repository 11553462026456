import React from "react"
import PropTypes from "prop-types"
import { AcfBrewGalleryBlockPreview } from "./queries"

import { makeStyles } from "@material-ui/styles"

import styles from "./styles"
import PreviewImage from "../../Reusable/PreviewImage"

const useStyles = makeStyles(theme => styles(theme))

export const AcfBrewGalleryBlock = ({ block }) => {
  const classes = useStyles()

  const { numberOfColumns, brewGallery } = block.blockBrewGallery

  return (
    <div className={"gallery " + classes.brewGallery}>
      <figure className={`wp-block-gallery columns-${numberOfColumns}`}>
        <ul className="blocks-gallery-grid">
          {brewGallery.map(image => (
            <li className="blocks-gallery-item">
              <PreviewImage
                alt={image.id}
                className="blocks-gallery-item"
                fallbackUrl
                image={image.localFile}
              />
            </li>
          ))}
        </ul>
      </figure>
    </div>
  )
}

AcfBrewGalleryBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export { AcfBrewGalleryBlockPreview }
