import { graphql } from "gatsby"

export const CoreHeadingBlockFragment = graphql`
  fragment CoreHeadingBlock on WpBlock {
    ... on WpCoreHeadingBlock {
      name
      attributes {
        __typename
        ... on WpCoreHeadingBlockAttributes {
          level
          anchor
          textColor
          backgroundColor
          className
          textAlign
          content
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const CoreHeadingBlockPreview = `
... on CoreHeadingBlock {
  name
  attributes {
    __typename
    ... on CoreHeadingBlockAttributes {
      level
      anchor
      textColor
      backgroundColor
      className
      textAlign
      content
    }
  }
}
`
