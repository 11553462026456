import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import GravityFormForm from "../../GravityForms"
import { makeStyles } from "@material-ui/styles"
import styles from "./styles"
import { trackFormSubmit } from "../../../services/gtm"

const useStyles = makeStyles(theme => styles(theme))

export const GravityformsFormBlock = props => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query formData {
      allGfForm {
        edges {
          node {
            ...GravityFormComponent
          }
        }
      }
    }
  `)

  const formTitle = data.allGfForm.edges.find(
    form => form.node.formId === Number(props.attributes.formId)
  ).node.title

  return (
    <div
      className={` ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.gravityForms}`}
    >
      <GravityFormForm
        id={Number(props.attributes.formId)}
        formData={data.allGfForm}
        lambda={`${process.env.GATSBY_BACKEND_URL}/wp-json/formsubmit/v1/submit/`}
        successCallback={() => {
          trackFormSubmit(formTitle)
        }}
        errorCallback={() => {}}
      />
    </div>
  )
}

GravityformsFormBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

// This is the GraphQL Fragment that will be used within Gatsby queries
export const GravityformsFormBlockFragment = graphql`
  fragment GravityformsFormBlock on WpBlock {
    ... on WpGravityformsFormBlock {
      name
      attributes {
        __typename
        ... on WpGravityformsFormBlockAttributes {
          formId
        }
      }
    }
  }
`

export const GravityformsFormBlockPreview = `
  ... on GravityformsFormBlock {
    name
    attributes {
        __typename
        ...on GravityformsFormBlockAttributes {
          formId
        }
      }
  }
  `
