import React from "react"
import PropTypes from "prop-types"
import { buildBlocks } from "../../../services/builder"
import { AcfArticleBannerBlockPreview } from "./queries"
import Wrapper from "../../Reusable/Wrapper"
import PreviewImage from "../../Reusable/PreviewImage"
import Icon from "./Icon"

import { makeStyles } from "@material-ui/styles"

import styles from "./styles"
import SocialShare from "../../SocialShare"

const useStyles = makeStyles(theme => styles(theme))

export const AcfArticleBannerBlock = ({
  innerBlocks,
  block,
  parentAttributes,
  pageContext,
  attributes,
}) => {
  const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext)
  const classes = useStyles()

  const { className } = attributes
  const { backgroundImage } = block.blockArticleBanner

  return (
    <div className={`article-banner ${className} ${classes.articleBanner} `}>
      <PreviewImage
        image={backgroundImage.localFile}
        fallbackUrl={backgroundImage.soruceUrl}
        alt=""
      />
      <Wrapper>
        <div className="con-wrap">
          <span className="read-time">
            <Icon /> {pageContext.readTime} min read
          </span>
          {blocks.map(block => {
            return block
          })}
        </div>
      </Wrapper>
      <SocialShare path={pageContext.pagePath} title={pageContext.pageTitle} />
    </div>
  )
}

AcfArticleBannerBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export { AcfArticleBannerBlockPreview }
