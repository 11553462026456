import React from "react"

export default function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="109.85"
      height="23.372"
      viewBox="0 0 109.85 23.372"
    >
      <path
        d="M-79.893,14.561H23.162L15.645,7.045a1.552,1.552,0,0,1,0-2.187,1.534,1.534,0,0,1,2.172,0l10.15,10.15a1.534,1.534,0,0,1,0,2.172l-10.15,10.15a1.536,1.536,0,1,1-2.172-2.172l7.516-7.516H-79.893a1.545,1.545,0,0,1-1.54-1.54A1.545,1.545,0,0,1-79.893,14.561Z"
        transform="translate(81.433 -4.408)"
      />
    </svg>
  )
}
