import React from "react"
import { BblTwoColumnWrapperInnerBlockPreview } from "./queries"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const BblTwoColumnWrapperInnerBlock = ({
  attributes,
  innerBlocks,
  parentAttributes,
  pageContext,
}) => {
  const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext)
  const classes = useStyles()

  const { className } = attributes

  return (
    <div
      className={`two-col-inner ${className} ${classes.twoColumnWrapperInner} `}
    >
      {blocks.map(block => {
        return block
      })}
    </div>
  )
}

export { BblTwoColumnWrapperInnerBlockPreview }
