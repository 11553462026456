import React from "react"
import { BblCalloutIntroBlockPreview } from "./queries"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"
import Wrapper from "../../Reusable/Wrapper"

const useStyles = makeStyles(theme => styles(theme))

export const BblCalloutIntroBlock = ({
  attributes,
  innerBlocks,
  parentAttributes,
  pageContext,
}) => {
  const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext)
  const classes = useStyles()

  const { className, preHeading, heading } = attributes

  return (
    <div className={`card-row ${className} ${classes.calloutIntro} `}>
      <Wrapper>
        <div className="intro-title">
          <span mode="single" className="pre">
            {preHeading}
          </span>
          <span mode="single" className="main">
            {heading}
          </span>
        </div>
        <div className="con-wrap">
          {blocks.map(block => {
            return block
          })}
        </div>
      </Wrapper>
    </div>
  )
}

export { BblCalloutIntroBlockPreview }
