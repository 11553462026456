import { graphql } from "gatsby"

export const BblCardRowBlockFragment = graphql`
  fragment BblCardRowBlock on WpBlock {
    ... on WpBblCardRowBlock {
      name
      attributes {
        className
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblCardRowBlockPreview = `
    ... on BblCardRowBlock {
        name
        attributes {
            className
        }
    }
`
