import { graphql } from "gatsby"

export const BblCalloutIntroBlockFragment = graphql`
  fragment BblCalloutIntroBlock on WpBlock {
    ... on WpBblCalloutIntroBlock {
      name
      attributes {
        preHeading
        heading
        className
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblCalloutIntroBlockPreview = `
    ... on BblCalloutIntroBlock {
        name
        attributes {
            preHeading
            heading
            className
        }
    }
`
