import React from "react"
import PropTypes from "prop-types"
import { CoreSpacerBlockPreview } from "./queries"

export const CoreSpacerBlock = ({ attributes }) => {
  const { className, heightPx } = attributes

  return <div className={className} style={{ height: heightPx }} />
}

CoreSpacerBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export { CoreSpacerBlockPreview }
