import React from "react"
import { makeStyles } from "@material-ui/styles"
import Link from "../Link"
import styles from "./styles"

import LinkedInIcon from "@material-ui/icons/LinkedIn"
import EmailIcon from "@material-ui/icons/Email"
import FacebookIcon from "@material-ui/icons/Facebook"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import TwitterIcon from "@material-ui/icons/Twitter"

const useStyles = makeStyles(theme => styles(theme))

export default function SocialShare(props) {
  const classes = useStyles()

  return (
    <div className={`SocialShare ${classes.socialShare}`}>
      <Link
        className="facebook"
        to={
          "https://www.facebook.com/sharer/sharer.php?u=" +
          process.env.GATSBY_FRONTEND_URL +
          props.path
        }
      >
        <FacebookIcon />
      </Link>
      <Link
        className="twitter"
        to={
          "https://twitter.com/intent/tweet?text=" +
          props.title +
          "&url=" +
          process.env.GATSBY_FRONTEND_URL +
          props.path
        }
      >
        <TwitterIcon />
      </Link>
      <Link
        className="email"
        to={
          "https://www.linkedin.com/shareArticle?mini=true&url=" +
          process.env.GATSBY_FRONTEND_URL +
          props.path +
          "&title=" +
          props.title +
          "&summary=&source="
        }
      >
        <LinkedInIcon />
      </Link>
      <Link
        className="linkedin"
        to={
          "mailto:?subject=" +
          props.title +
          "&body=" +
          process.env.GATSBY_FRONTEND_URL +
          props.path
        }
      >
        <EmailIcon />
      </Link>
      <Link
        className="whatsapp"
        to={
          "whatsapp://send?text=" + process.env.GATSBY_FRONTEND_URL + props.path
        }
      >
        <WhatsAppIcon />
      </Link>
    </div>
  )
}
