import React from "react"
import PropTypes from "prop-types"
import { CoreImageBlockPreview } from "./queries"
import PreviewImage from "../../Reusable/PreviewImage"
import HTMLReactParser from "html-react-parser"

export const CoreImageBlock = ({ attributes, originalContent }) => {
  const { url, className, localFile, alt, caption } = attributes

  if (!url) return null

  let showCaption = true

  if (originalContent.indexOf("figcaption") === -1) {
    showCaption = false
  }

  return (
    <div
      className={"gatsby-caption-wrapper " + className}
      style={{ position: "relative" }}
    >
      <PreviewImage image={localFile} fallbackUrl={url} alt={alt} />
      {showCaption && <div className="caption">{HTMLReactParser(caption)}</div>}
    </div>
  )
}

CoreImageBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export { CoreImageBlockPreview }
