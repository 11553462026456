import { graphql } from "gatsby"

export const AcfBrewGalleryBlockFragment = graphql`
  fragment AcfBrewGalleryBlock on WpBlock {
    ... on WpAcfBrewGalleryBlock {
      name
      attributes {
        className
      }
      blockBrewGallery {
        brewGallery {
          id
          uri
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 920
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                transformOptions: { fit: COVER, cropFocus: CENTER }
                aspectRatio: 1
              )
            }
          }
        }
        numberOfColumns
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfBrewGalleryBlockPreview = `
    ... on AcfBrewGalleryBlock {
        name
        attributes {
            className
        }
        blockBrewGallery {
            brewGallery {
                id
                uri
            }           
        }
        numberOfColumns
    }
}`
