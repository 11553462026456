import { graphql } from "gatsby"

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CoreListBlockFragment = graphql`
  fragment CoreListBlock on WpBlock {
    ... on WpCoreListBlock {
      name
      attributes {
        ... on WpCoreListBlockAttributes {
          className
          ordered
          reversed
          start
          type
          values
        }
      }
    }
  }
`

export const CoreListBlockPreview = `
  ...on CoreListBlock {
    name
    attributes {
      ...on CoreListBlockAttributes {
        className
        ordered
        reversed
        start
        type
        values
      }
    }
  }
  `
