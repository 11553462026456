import texture from "../../../images/cover-texture.png"

export default theme => ({
  // eslint-disable-line
  magazineSlider: {
    position: "relative",
    zIndex: 0,
    boxShadow:
      "-300px 0 var(--uow-white), 300px 0 var(--uow-white), -600px 0 var(--uow-white), 600px 0 var(--uow-white)",
    "& .sliders": {
      position: "relative",
      "& .slick-slider.background": {
        zIndex: 0,
        position: "absolute",
        top: -100,
        left: "50%",
        width: "100vw",
        transform: "translateX(-50%)",
        bottom: 0,
        [theme.breakpoints.up("sm")]: {
          top: -120,
        },
        "& .slick-list": {
          "& .slick-track": {
            "& .slick-slide": {
              "& .slide": {
                backgroundColor: "var(--page-accent)",
                "& .life-grid": {
                  display: "block",
                  width: "100vw",
                  height: 500,
                  opacity: 0.33,
                  mixBlendMode: "multiply",
                  backgroundPosition: "center top",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  [theme.breakpoints.up("sm")]: {
                    height: 600,
                  },
                },
              },
            },
          },
        },
      },
      "& .slick-slider.magazines": {
        width: 440,
        marginLeft: "50%",
        transform: "translateX(-50%)",
        zIndex: 1,
        margin: "0 auto",
        paddingBottom: 100,
        [theme.breakpoints.up("sm")]: {
          width: 1540,
          paddingTop: 290,
          paddingBottom: 0,
          "&:after": {
            background:
              "linear-gradient(180deg, rgba(249,249,249,0) 0%, rgba(249,249,249,1) 50%)",
            display: "block",
            content: '""',
            position: "absolute",
            left: -600,
            right: -600,
            bottom: 0,
            height: 120,
            zIndex: 10,
          },
        },
        "& .slick-list": {
          overflow: "visible",
          "& .slick-track": {
            "& .slick-slide": {
              padding: 12,
              position: "relative",
              [theme.breakpoints.up("sm")]: {
                padding: "12px 90px",
                transform: "rotate(-40deg)",
                transition: "all .3s ease-in-out",
              },
              "& a": {
                display: "block",
                position: "relative",
                borderLeft: "5px solid var(--page-accent)",
                borderTop: "5px solid var(--page-accent)",
                paddingBottom: "150%",
                textDecoration: "none",
                backgroundSize: "cover",
                backgroundPosition: "center",
                zIndex: 10,
                boxShadow: "0px 12px 32px rgba(24,44,68,.28)",
                transition: "opacity .3s ease-in-out",
                [theme.breakpoints.up("sm")]: {
                  opacity: 0,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundSize: "cover",
                  backgroundImage: "url(" + texture + ")",
                  backgroundPosition: "center",
                  zIndex: 1,
                },
                "&.uncreased:before": {
                  backgroundImage: "none",
                },
                "&:after": {
                  content: '"Life"',
                  fontSize: 38,
                  letterSpacing: "0.024em",
                  lineHeight: "30px",
                  fontFamily: "var(--uow-header-font)",
                  color: "var(--page-accent)",
                  transformOrigin: "0 100%",
                  transform: "rotate(90deg)",
                  fontWeight: "bold",
                  display: "block",
                  position: "absolute",
                  top: -10,
                  left: -2,
                  zIndex: 2,
                },
                "& .title": {
                  color: "transparent",
                  position: "absolute",
                  top: 18,
                  right: 18,
                  fontFamily: "var(--uow-body-font)",
                  textTransform: "uppercase",
                  width: 225,
                  textAlign: "right",
                  fontSize: 52,
                  fontWeight: "bold",
                  lineHeight: "48px",
                  zIndex: 3,
                  "-webkit-text-stroke-width": "2px",
                  "-webkit-text-stroke-color": "var(--uow-white)",
                },
                "& .desc": {
                  position: "absolute",
                  bottom: 30,
                  right: 30,
                  left: 30,
                  color: "var(--uow-white)",
                  fontFamily: "var(--uow-body-font)",
                  fontSize: 14,
                  lineHeight: 1.5,
                  opacity: 0,
                  zIndex: 3,
                  transition: "opacity .3s ease-in-out",
                },
              },
              "&.slick-active": {
                "& a": {
                  opacity: 1,
                },
              },
              "&.slick-current": {
                [theme.breakpoints.up("sm")]: {
                  transform: "scale(1.35) rotate(0deg) translateY(-165px)",
                },
                "& a": {
                  opacity: 1,
                  "& .desc": {
                    opacity: 1,
                  },
                },
                "& + .slick-active": {
                  transform: "rotate(40deg)",
                },
              },
            },
          },
        },
        "& .slick-prev": {
          left: "50%",
          right: "auto",
          transform: "translateX(calc(-100% - 50px))",
        },
        "& .slick-next": {
          right: "50%",
          left: "auto",
          transform: "translateX(calc(100% + 50px))",
        },
        "& .slick-prev, & .slick-next": {
          zIndex: 20,
          width: 50,
          height: 50,
          top: "auto",
          bottom: 40,

          [theme.breakpoints.up("sm")]: {
            bottom: 80,
          },
        },
        "& .slick-prev::before, & .slick-next::before": {
          content: "none",
          fontSize: 40,
          color: "var(--uow-royal-blue)",
        },
        "& .slick-dots": {
          bottom: 75,
          transform: "scaleX(1.25)",
          [theme.breakpoints.up("sm")]: {
            bottom: 120,
          },
          "& li": {
            fontSize: 0,
            lineHeight: 0,
            width: "auto",
            height: "auto",
            padding: 0,
            "& button": {
              width: 10,
              height: 10,
              border: "2px solid var(--uow-royal-blue)",
              fontSize: 0,
              lineHeight: 0,
              padding: 0,
              backgroundColor: "transparent",
              transition: "background-color .3s ease-in-out",
              transform: "rotate(45deg)",
              "&:before": {
                display: "none",
              },
            },
            "&.slick-active": {
              "& button": {
                backgroundColor: "var(--uow-royal-blue)",
              },
            },
          },
        },
      },
    },
  },
})
