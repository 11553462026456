import React from "react"
import { makeStyles } from "@material-ui/styles"
import styles from "./styles"
import Link from "../../Link"
import parse from "html-react-parser"

const useStyles = makeStyles(theme => styles(theme))

export default function BrewButton({ link, background, text }) {
  const classes = useStyles()

  return (
    <Link
      className={`button ${classes.button}`}
      to={link.replace(process.env.GATSBY_FRONTEND_URL, "")}
      data-override-background={background}
    >
      <span>{parse(text !== null ? text : "")}</span>
    </Link>
  )
}
