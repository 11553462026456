export default theme => ({
  video: {
    aspectRatio: "16/9",
    position: "relative",
    "& iframe": {
      position: "absolute",
      top: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
    },
  },
})
