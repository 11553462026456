import { graphql } from "gatsby"

export const AcfCardLinkBlockFragment = graphql`
  fragment AcfCardLinkBlock on WpBlock {
    ... on WpAcfCardLinkBlock {
      name
      attributes {
        className
      }
      BlockCardLink {
        backgroundColour
        link {
          url
        }
      }
      innerImage: innerBlocks {
        ... on WpCoreImageBlock {
          name
          originalContent
          attributes {
            __typename
            ... on WpCoreImageBlockAttributes {
              url
              alt
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 1024
                    aspectRatio: 1.75
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfCardLinkBlockPreview = `
    ... on AcfCardLinkBlock {
        name
        attributes {
            className
        }
        BlockCardLink {
            backgroundColour
            link {
                ... on WpPage {
                    uri
                }
                ... on WpPost {
                    uri
                }
            }
        }
        inner: innerBlocks {
            ... on WpCoreImageBlock {
                name
                originalContent {
                    attributes {
                        __typename
                        ... on WpCoreImageBlockAttributes {
                            url
                        }
                    }
                }
            }
        }
    }
`
