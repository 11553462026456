export default theme => ({
  // eslint-disable-line
  textColumn: {
    padding: "40px 0",
    "& .con-wrap": {
      padding: "0 12px",
      width: "83.333%",
      margin: "0 auto",
      "& > *": {
        marginBottom: 24,
      },
      "& > .MuiTypography-h1, & > .MuiTypography-h2, & > .MuiTypography-h3, & > .MuiTypography-h4, & > .MuiTypography-h5, & > .MuiTypography-h6": {
        marginBottom: 32,
      },
    },
    '&[data-thin="true"]': {
      "& .con-wrap": {
        [theme.breakpoints.up("md")]: {
          width: "66.666%",
        },
      },
    },
  },
})
