import React from "react"
import { BblFiftyFiftyFormBlockPreview } from "./queries"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"
import Wrapper from "../../Reusable/Wrapper"
import LifeGrid from "../../Reusable/LifeGrid"
import { Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => styles(theme))

export const BblFiftyFiftyFormBlock = ({
  attributes,
  innerBlocks,
  parentAttributes,
  pageContext,
}) => {
  const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext)
  const classes = useStyles()

  const { className, heading } = attributes

  return (
    <div className={`fifty-fifty-form ${className} ${classes.fiftyfiftyForm} `}>
      <Wrapper>
        <LifeGrid color="uow-royal-blue" />
        <Typography
          component={"h1"}
          variant={"h1"}
          className={className}
          data-override-color={"uow-white"}
        >
          {heading}
        </Typography>
        <div className="columns">
          <div className="text">
            {blocks[1]}
            {blocks[2]}
            {blocks[3]}
            {blocks[4]}
          </div>
          <div className="form">{blocks[0]}</div>
        </div>
      </Wrapper>
    </div>
  )
}

export { BblFiftyFiftyFormBlockPreview }
