import bg from "../../../images/blur-dots.png"
import flag from "../../../images/flag.png"

export default theme => ({
  // eslint-disable-line
  calloutIntro: {
    backgroundColor: "var(--uow-royal-blue)",
    backgroundImage: "url(" + bg + ")",
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginLeft: -12,
    marginRight: -12,
    padding: "130px 0",
    [theme.breakpoints.up("md")]: {
      padding: "260px 0",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100vw",
      marginLeft: "50%",
      transform: "translateX(-50vw)",
      marginRight: 0,
    },
    "& .intro-title": {
      flex: "0 0 83.333%",
      width: "83.333%",
      margin: "0 8.333%",
      padding: "0 12px",
      marginBottom: 50,
      position: "relative",
      [theme.breakpoints.up("md")]: {
        flex: "0 0 33.333%",
        width: "33.333%",
        marginBottom: 0,
      },
      "&:before": {
        content: '""',
        width: 60,
        height: 100,
        backgroundImage: "url(" + flag + ")",
        backgroundPosition: "left top",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        display: "block",
        position: "absolute",
        top: -130,
        left: 12,
        [theme.breakpoints.up("md")]: {
          width: 75,
          height: 125,
          top: -260,
        },
      },
      "& .pre": {
        color: "var(--uow-royal-blue)",
        textTransform: "uppercase",
        display: "block",
        fontSize: "9.2vw",
        "-webkit-text-stroke-width": "1px",
        "-webkit-text-stroke-color": "var(--uow-white)",
        lineHeight: 1,
        [theme.breakpoints.up("md")]: {
          fontSize: "4.1vw",
        },
        [theme.breakpoints.up(1344)]: {
          fontSize: 59,
        },
      },
      "& .main": {
        fontFamily: "var(--uow-header-font)",
        color: "var(--uow-white)",
        fontWeight: "bold",
        lineHeight: 1,
        display: "block",
        fontSize: "14.6vw",
        [theme.breakpoints.up("md")]: {
          fontSize: "6.6vw",
        },
        [theme.breakpoints.up(1344)]: {
          fontSize: 94,
        },
      },
    },
    "& .con-wrap": {
      flex: "0 0 83.333%",
      width: "83.333%",
      margin: "0 8.333%",
      padding: "0 12px",
      [theme.breakpoints.up("md")]: {
        flex: "0 0 41.666%",
        width: "41.666%",
        margin: 0,
      },
      "& p": {
        color: "var(--uow-white)",
        marginBottom: 50,
      },
    },
  },
})
