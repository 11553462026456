export default theme => ({
  // eslint-disable-line
  twoColumnWrapperInner: {
    flex: "0 0 calc(83.333% - 24px)",
    margin: 0,
    [theme.breakpoints.up("md")]: {
      flex: "0 0 calc(41.666% - 64px)",
      margin: "0 52px",
    },
    "& .MuiTypography-root": {
      marginBottom: 32,
      "&.MuiTypography-paragraph": {
        marginBottom: 24,
      },
    },
    "& .button": {
      marginBottom: 24,
    },
  },
})
