import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

export default function PreviewImage(props) {
  let image = null

  // Use the gatsby image if available
  if (props.image) {
    if (props.image.childImageSharp) {
      image = (
        <GatsbyImage
          image={props.image.childImageSharp.gatsbyImageData}
          alt={props.alt}
          className={props.className}
        />
      )
    } else {
      // Gifs do not have a childImageSharp
      image = (
        <div className={`gatsby-image-wrapper ${props.className}`}>
          <div
            style={{
              paddingBottom:
                props.fixedPadding != null ? props.fixedPadding : "55%",
            }}
          />
          <img
            src={props.image.publicURL}
            alt={props.alt}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center center",
            }}
          />
        </div>
      )
    }
  } else {
    // This is a preview image
    image = (
      <div className={`gatsby-image-wrapper ${props.className}`}>
        <div
          style={{
            paddingBottom:
              props.fixedPadding != null ? props.fixedPadding : "66.666%",
          }}
        />
        <img
          src={props.fallbackUrl}
          alt={props.alt}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center center",
          }}
        />
      </div>
    )
  }

  return image
}
