import { graphql } from "gatsby"

export const AcfArticleBannerBlockFragment = graphql`
  fragment AcfArticleBannerBlock on WpBlock {
    ... on WpAcfArticleBannerBlock {
      name
      attributes {
        className
      }
      blockArticleBanner {
        backgroundImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfArticleBannerBlockPreview = `
    ... on AcfArticleBannerBlock {
        name
        attributes {
            className
        }
        blockArticleBanner {
          backgroundImage {
            sourceUrl
          }
        }
    }
}`
