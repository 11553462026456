import { graphql } from "gatsby"

export const BblQuoteBlockFragment = graphql`
  fragment BblQuoteBlock on WpBlock {
    ... on WpBblQuoteBlock {
      name
      attributes {
        className
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblQuoteBlockPreview = `
    ... on BblQuoteBlock {
        name
        attributes {
            className
        }
    }
`
