import arrow from "../../../images/arrow.png"

export default theme => ({
  // eslint-disable-line
  gravityForms: {
    margin: "0 auto",
    width: "calc(100% - 24px)",
    maxWidth: 1118,
    "& .MuiTypography-root": {
      marginBottom: 32,
    },
    "& .gfield": {
      marginBottom: 16,
      display: "block",
      "& .ginput_container": {
        "& > label": {
          display: "block",
          fontSize: 13.5,
          textTransform: "uppercase",
          letterSpacing: "0.152em",
          fontFamily: "var(--uow-body-font)",
          lineHeight: "24px",
          color: "var(--uow-royal-blue)",
          "& .gfield_required": {
            color: "var(--uow-pink)",
            marginLeft: 10,
          },
        },
        '& input:not([type="checkbox"], [type="radio"]), & select, & textarea': {
          padding: "12px 16px",
          fontSize: 13.5,
          fontFamily: "var(--uow-body-font)",
          color: "var(--uow-royal-blue)",
          border: "1px solid var(--uow-gray)",
          lineHeight: 1.2,
          borderRadius: 4,
          width: "100%",
          letterSpacing: "0.032em",
          outline: "none",
          backgroundColor: "var(--uow-white)",
          appearance: "none",
          transition: "background-color .3s ease-in-out",
          "&:focus": {
            outline: "none",
          },
        },
        "& textarea": {
          height: 85,
        },
        "& select": {
          backgroundImage:
            "linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%)",
          backgroundPosition: "calc(100% - 23px) 18px, calc(100% - 15px) 18px",
          backgroundSize: "9px 6px, 9px 6px",
          backgroundRepeat: "no-repeat",
          "& option": {
            backgroundColor: "white !important",
          },
        },
      },
      "&.gravityform__field__checkbox, &.gravityform__field__radio": {
        "& > label": {
          fontSize: 12,
          lineHeight: 1.2,
          letterSpacing: "0.32em",
          color: "var(--uow-royal-blue)",
          textTransform: "uppercase",
          fontFamily: "var(--uow-body-font)",
          marginBottom: 16,
          display: "block",
        },
        "& .gfield_checkbox, & .gfield_radio": {
          '& [type="checkbox"], & [type="radio"]': {
            position: "absolute",
            left: 0,
            opacity: 0.01,
            "& + label": {
              position: "relative",
              paddingLeft: 26,
              fontSize: 14,
              lineHeight: 1.5,
              cursor: "pointer",
              marginRight: 12,
              marginBottom: 12,
              display: "table",
              color: "var(--uow-royal-blue)",
              letterSpacing: "0.032em",
              "&::before": {
                content: '""',
                position: "absolute",
                left: 0,
                top: 0,
                width: 18,
                height: 18,
                borderRadius: 2,
                border: "2px solid rgba(0,0,0,0.38)",
                background: "white",
                transition: "all .3s ease-in-out",
              },
              "&::after": {
                content: '""',
                position: "absolute",
                top: 4,
                left: 3,
                width: 12,
                height: 7,
                transition: "all .3s ease-in-out",
                borderLeft: "2px solid transparent",
                borderBottom: "2px solid transparent",
                transform: "rotate(-45deg)",
              },
            },
            "&:checked": {
              "& + label": {
                position: "relative",
                paddingLeft: 26,
                fontSize: 14,
                lineHeight: 1.5,
                cursor: "pointer",
                "&::before": {
                  backgroundColor: "var(--page-accent)",
                  borderColor: "var(--page-accent)",
                },
                "&::after": {
                  borderLeft: "2px solid white",
                  borderBottom: "2px solid white",
                },
              },
            },
          },
          '& input[type="radio"]': {
            "& + label": {
              "&::before": {
                borderRadius: 9,
                backgroundColor: "transparent !important",
              },
              "&::after": {
                top: 4,
                left: 4,
                width: 10,
                height: 10,
                borderRadius: 5,
                border: "none !important",
              },
            },
            "&:checked": {
              "& + label": {
                "&::before": {
                  backgroundColor: "transparent !important",
                },
                "&::after": {
                  backgroundColor: "var(--page-accent)",
                },
              },
            },
          },
        },
      },
      "& .gravityform__error_message": {
        fontSize: 12,
        padding: "12px 0",
        letterSpacing: "0.032em",
        color: "#B00020",
      },
    },
    "& .gform_footer": {
      "& p": {
        display: "block",
        fontSize: 13.5,
        textTransform: "uppercase",
        letterSpacing: "0.152em",
        fontFamily: "var(--uow-body-font)",
        lineHeight: "24px",
        color: "var(--uow-royal-blue)",
        marginBottom: 32,
        "& span": {
          color: "var(--uow-pink)",
        },
      },
      "& button": {
        fontSize: 18,
        textTransform: "uppercase",
        padding: "14px 32px 10px",
        fontWeight: "bold",
        borderRadius: 4,
        boxShadow: "0 4px 6px rgba(0,0,0,.25)",
        textDecoration: "none",
        backgroundColor: "var(--uow-orange-yellow)",
        color: "var(--uow-royal-blue)",
        display: "table",
        border: "none",
        fontFamily: "var(--uow-body-font)",
        letterSpacing: "0.088em",
        cursor: "pointer",
        "&:before": {
          position: "relative",
          backgroundImage: "url(" + arrow + ")",
          backgroundSize: "14px 16px",
          width: 0,
          height: 16,
          marginRight: 0,
          display: "inline-block",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center right",
          backgroundColor: "transparent",
          left: "auto",
          right: "auto",
          verticalAlign: "middle",
          marginTop: -4,
          content: '""',
          transition: "all .3s ease-in-out",
        },
        "&:hover, &:focus": {
          "&:before": {
            left: "auto",
            right: "auto",
            width: 14,
            marginRight: 14,
          },
        },
      },
    },
  },
})
