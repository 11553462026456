import { graphql } from "gatsby"

export const AcfVideoBlockFragment = graphql`
  fragment AcfVideoBlock on WpBlock {
    ... on WpAcfVideoBlock {
      name
      attributes {
        className
      }
      blockVideo {
        platform
        videoId
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfVideoBlockPreview = `
    ... on AcfVideoBlock {
        name
        attributes {
            className
        }
        blockVideo {
          platform
          videoId
        }
    }
`
