import React from "react"
import { BblCardRowBlockPreview } from "./queries"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"
import Wrapper from "../../Reusable/Wrapper"

const useStyles = makeStyles(theme => styles(theme))

export const BblCardRowBlock = ({
  attributes,
  innerBlocks,
  parentAttributes,
  pageContext,
}) => {
  const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext)
  const classes = useStyles()

  const { className } = attributes

  return (
    <div className={`card-row ${className} ${classes.cardRow} `}>
      <Wrapper>
        {blocks.map(block => {
          return block
        })}
      </Wrapper>
    </div>
  )
}

export { BblCardRowBlockPreview }
