import { graphql } from "gatsby"

export const BblTextColumnBlockFragment = graphql`
  fragment BblTextColumnBlock on WpBlock {
    ... on WpBblTextColumnBlock {
      name
      attributes {
        className
        isThin
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblTextColumnBlockPreview = `
    ... on BblTextColumnBlock {
        name
        attributes {
            className
            isThin
        }
    }
`
