import React from "react"

export default function IconStart() {
  return (
    <svg x="0px" y="0px" viewBox="0 0 101 93">
      <path
        className="shadow"
        d="M0,66.9c0-8.9,2.4-18.4,7.1-28.3C11.4,29.3,17.4,21,24.8,14l19.5,10.6c-3.5,6.2-6.3,12.7-8.2,19.6 c-1.9,7.4-2.9,15-2.8,22.7V93H0V66.9z"
      />
      <path
        className="shadow"
        d="M45.7,66.9c0-8.9,2.4-18.4,7.1-28.3C57,29.3,63,21,70.5,14L90,24.6c-3.5,6.2-6.3,12.7-8.2,19.6 c-1.9,7.4-2.9,15-2.8,22.7V93H45.7V66.9z"
      />
      <path
        className="accent"
        d="M11,52.9c0-8.9,2.4-18.4,7.1-28.3C22.4,15.3,28.4,6.9,35.8,0l19.5,10.6c-3.5,6.2-6.3,12.7-8.2,19.6 c-1.9,7.4-2.9,15-2.8,22.7V79H11V52.9z"
      />
      <path
        className="accent"
        d="M56.7,52.9c0-8.9,2.4-18.4,7.1-28.3C68,15.3,74,6.9,81.5,0L101,10.6c-3.5,6.2-6.3,12.7-8.2,19.6c-1.9,7.4-2.9,15-2.8,22.7 V79H56.7V52.9z"
      />
    </svg>
  )
}
