import { graphql } from "gatsby"

export const BblFiftyFiftyImagesBlockFragment = graphql`
  fragment BblFiftyFiftyImagesBlock on WpBlock {
    ... on WpBblFiftyFiftyImagesBlock {
      name
      attributes {
        className
        alignLeft
        alignBottom
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblFiftyFiftyImagesBlockPreview = `
    ... on BblFiftyFiftyImagesBlock {
        name
        attributes {
            className
            alignLeft
            alignBottom
        }
    }
`
