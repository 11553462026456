import React from "react"
import { BblImageTextStaggeredBlockPreview } from "./queries"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"
import Wrapper from "../../Reusable/Wrapper"

const useStyles = makeStyles(theme => styles(theme))

export const BblImageTextStaggeredBlock = ({
  attributes,
  innerBlocks,
  parentAttributes,
  pageContext,
}) => {
  const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext)
  const classes = useStyles()

  const { className, photoLeft } = attributes

  return (
    <div
      className={`image-text-staggered ${className} ${classes.imageTextStaggered} `}
      data-photo-left={photoLeft}
    >
      <Wrapper>
        {blocks[0]}
        <div className="content">
          {blocks[1]}
          {blocks[2]}
        </div>
      </Wrapper>
    </div>
  )
}

export { BblImageTextStaggeredBlockPreview }
