import arrow from "../../../images/arrow.png"

const styles = theme => ({
  button: {
    fontSize: 18,
    textTransform: "uppercase",
    padding: "14px 32px 10px",
    fontWeight: "bold",
    borderRadius: 4,
    boxShadow: "0 4px 6px rgba(0,0,0,.25)",
    textDecoration: "none",
    backgroundColor: "var(--uow-orange-yellow)",
    color: "var(--uow-royal-blue)",
    display: "table",
    fontFamily: "var(--uow-body-font)",
    letterSpacing: "0.088em",
    cursor: "pointer",
    "&:before": {
      position: "relative",
      backgroundImage: "url(" + arrow + ")",
      backgroundSize: "14px 16px",
      width: 0,
      height: 16,
      marginRight: 0,
      display: "inline-block",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center right",
      backgroundColor: "transparent",
      left: "auto",
      right: "auto",
      verticalAlign: "middle",
      marginTop: -4,
      content: '""',
      transition: "all .3s ease-in-out",
    },
    "&:hover, &:focus": {
      "&:before": {
        left: "auto",
        right: "auto",
        width: 14,
        marginRight: 14,
      },
    },
  },
})

export default styles
