import React from "react"
import PropTypes from "prop-types"
import { AcfThreeColumnContentBlockPreview } from "./queries"
import { buildBlocks } from "../../../services/builder"
import Wrapper from "../../Reusable/Wrapper"

import { makeStyles } from "@material-ui/styles"

import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const AcfThreeColumnContentBlock = ({
  attributes,
  block,
  innerBlocks,
  parentAttributes,
  pageContext,
}) => {
  const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext)
  const classes = useStyles()

  const { className } = attributes
  const { backgroundColour } = block.blockThreeColumnContent

  return (
    <div
      className={`three-column-content ${className} ${classes.threeColumnContent} `}
      style={{ backgroundColor: "var(--" + backgroundColour + ")" }}
    >
      <Wrapper>
        <div className="head-img">
          {blocks[2]}
          {blocks[3]}
        </div>
        {blocks[1]}
        {blocks[0]}
      </Wrapper>
    </div>
  )
}

AcfThreeColumnContentBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export { AcfThreeColumnContentBlockPreview }
