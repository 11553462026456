import React from "react"
import parse from "html-react-parser"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import { transformLink } from "../../../helpers"
import { CoreHeadingBlockPreview } from "./queries"

export const CoreHeadingBlock = ({ attributes }) => {
  const {
    content,
    anchor,
    level,
    textAlign,
    className,
    textColor,
    backgroundColor,
  } = attributes

  if (content === "") return null

  return (
    <React.Fragment>
      {anchor !== "" && <a id={`${anchor}`}></a> // eslint-disable-line
      }
      <Typography
        component={level !== "" ? `h${level}` : "h2"}
        variant={level !== "" ? `h${level}` : "h2"}
        color="initial"
        align={textAlign !== "" ? textAlign : "left"}
        className={className}
        data-override-color={textColor !== "" ? textColor : "initial"}
        data-override-background={
          backgroundColor !== "" ? backgroundColor : "initial"
        }
      >
        {parse(content !== null ? content : "", { transform: transformLink })}
      </Typography>
    </React.Fragment>
  )
}

CoreHeadingBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export { CoreHeadingBlockPreview }
