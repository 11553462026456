import React from "react"
import { BblTextColumnBlockPreview } from "./queries"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"
import Wrapper from "../../Reusable/Wrapper"

const useStyles = makeStyles(theme => styles(theme))

export const BblTextColumnBlock = ({
  attributes,
  innerBlocks,
  parentAttributes,
  pageContext,
}) => {
  const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext)
  const classes = useStyles()

  const { className, isThin } = attributes

  return (
    <div
      className={`text-column ${className} ${classes.textColumn} `}
      data-thin={isThin}
    >
      <Wrapper>
        <div className="con-wrap">
          {blocks.map(block => {
            return block
          })}
        </div>
      </Wrapper>
    </div>
  )
}

export { BblTextColumnBlockPreview }
