import React from "react"
import PropTypes from "prop-types"
import { AcfBackgroundImageIntroBlockPreview } from "./queries"
import { buildBlocks } from "../../../services/builder"
import Wrapper from "../../Reusable/Wrapper"

import { makeStyles } from "@material-ui/styles"

import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const AcfBackgroundImageIntroBlock = ({
  innerBlocks,
  block,
  parentAttributes,
  pageContext,
  attributes,
}) => {
  const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext)
  const classes = useStyles()

  const { className } = attributes
  const { backgroundImage } = block.blockBackgroundImageIntro

  return (
    <div
      className={`background-image-intro ${className} ${classes.backgroundImageIntro} `}
    >
      <div className="background">
        <div
          className="image"
          style={{
            backgroundImage:
              backgroundImage.localFile !== null
                ? "url(" + backgroundImage.localFile.publicURL + ")"
                : "url(" + backgroundImage.soruceUrl + ")",
          }}
        />
      </div>
      <Wrapper>
        <div className="con-wrap">
          {blocks.map(block => {
            return block
          })}
        </div>
      </Wrapper>
    </div>
  )
}

AcfBackgroundImageIntroBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export { AcfBackgroundImageIntroBlockPreview }
