import texture from "../../../images/article-banner-texture.png"

export default theme => ({
  // eslint-disable-line
  articleBanner: {
    backgroundColor: "var(--uow-royal-blue)",
    position: "relative",
    marginBottom: 50,
    [theme.breakpoints.up("md")]: {
      marginBottom: 100,
    },
    "& .gatsby-image-wrapper": {
      position: "relative",
      zIndex: 1,
      [theme.breakpoints.up("md")]: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      "&:before": {
        content: '""',
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        display: "block",
        position: "absolute",
        opacity: 0.5,
        zIndex: 1,
        backgroundImage: "url(" + texture + ")",
      },
      "&:after": {
        content: '""',
        bottom: 0,
        left: 0,
        right: 0,
        height: "50%",
        zIndex: 2,
        display: "block",
        background:
          "linear-gradient(0deg, rgba(16,44,69,1) 0%, rgba(16,44,69,0.1) 40%, rgba(16,44,69,0) 100%)",
        position: "absolute",
        [theme.breakpoints.up("md")]: {
          height: "auto",
          top: 0,
          right: "auto",
          width: "50%",
          background:
            "linear-gradient(90deg, rgba(0,0,0,.6) 0%, rgba(0,0,0,0.4) 60%, rgba(0,0,0,0) 100%)",
        },
      },
    },
    "& .wrapper": {
      zIndex: 10,
      position: "relative",
      "& .con-wrap": {
        padding: "32px 12px 8px",
        width: "83.333%",
        margin: "0 auto",
        position: "relative",
        "&:before": {
          content: '""',
          width: 34,
          height: 84,
          display: "block",
          position: "absolute",
          right: 12,
          top: -42,
          backgroundColor: "var(--page-accent)",
          clipPath:
            "polygon(0% 22px, 100% 0%, 100% calc(100% - 22px), 0% 100%)",
          [theme.breakpoints.up("md")]: {
            clipPath:
              "polygon(0% 45px, 100% 0%, 100% calc(100% - 45px), 0% 100%)",
            width: 68,
            height: 168,
            right: "auto",
            left: 12,
            top: "auto",
            bottom: -84,
          },
        },
        [theme.breakpoints.up("md")]: {
          width: "41.666%",
          marginLeft: "8.333%",
          padding: "160px 12px 148px",
          minHeight: 775,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        },
        [theme.breakpoints.up("lg")]: {
          width: "33.333%",
        },
        "& .read-time": {
          marginBottom: 24,
          color: "var(--page-accent)",
          fontFamily: "var(--body-font)",
          fontSize: 13.5,
          letterSpacing: "0.152em",
          textTransform: "uppercase",
          display: "block",
          "& svg": {
            marginRight: 10,
          },
        },
        "& .MuiTypography-root": {
          color: "var(--uow-white)",
          marginBottom: 24,
        },
      },
    },
  },
})
