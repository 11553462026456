/* eslint-disable */
import { graphql } from "gatsby"

import {
  AcfArticleBannerBlockFragment,
  AcfArticleBannerBlockPreview,
} from "../components/blocks/AcfArticleBannerBlock"
import {
  AcfBackgroundImageIntroBlockFragment,
  AcfBackgroundImageIntroBlockPreview,
} from "../components/blocks/AcfBackgroundImageIntroBlock"
import {
  AcfBrewGalleryBlockFragment,
  AcfBrewGalleryBlockPreview,
} from "../components/blocks/AcfBrewGalleryBlock"
import {
  AcfButtonBlockFragment,
  AcfButtonBlockPreview,
} from "../components/blocks/AcfButtonBlock"
import {
  AcfCardLinkBlockFragment,
  AcfCardLinkBlockPreview,
} from "../components/blocks/AcfCardLinkBlock"
import {
  AcfThreeColumnContentBlockFragment,
  AcfThreeColumnContentBlockPreview,
} from "../components/blocks/AcfThreeColumnContentBlock"
import {
  AcfVideoBlockFragment,
  AcfVideoBlockPreview,
} from "../components/blocks/AcfVideoBlock"

import {
  BblBackgroundTextBlockFragment,
  BblBackgroundTextBlockPreview,
} from "../components/blocks/BblBackgroundTextBlock"
import {
  BblCalloutIntroBlockFragment,
  BblCalloutIntroBlockPreview,
} from "../components/blocks/BblCalloutIntroBlock"
import {
  BblCardRowBlockFragment,
  BblCardRowBlockPreview,
} from "../components/blocks/BblCardRowBlock"
import {
  BblFiftyFiftyFormBlockFragment,
  BblFiftyFiftyFormBlockPreview,
} from "../components/blocks/BblFiftyFiftyFormBlock/"
import {
  BblFiftyFiftyImagesBlockFragment,
  BblFiftyFiftyImagesBlockPreview,
} from "../components/blocks/BblFiftyFiftyImagesBlock"
import {
  BblImageColumnBlockFragment,
  BblImageColumnBlockPreview,
} from "../components/blocks/BblImageColumnBlock"
import {
  BblImageTextColumnsBlockFragment,
  BblImageTextColumnsBlockPreview,
} from "../components/blocks/BblImageTextColumnsBlock"
import {
  BblImageTextStaggeredBlockFragment,
  BblImageTextStaggeredBlockPreview,
} from "../components/blocks/BblImageTextStaggeredBlock"
import {
  BblOffsetImageBlockFragment,
  BblOffsetImageBlockPreview,
} from "../components/blocks/BblOffsetImageBlock"
import {
  BblIntroImageColumnsBlockFragment,
  BblIntroImageColumnsBlockPreview,
} from "../components/blocks/BblIntroImageColumnsBlock"
import {
  BblIntroTextBlockFragment,
  BblIntroTextBlockPreview,
} from "../components/blocks/BblIntroTextBlock"
import {
  BblMagazineSliderBlockFragment,
  BblMagazineSliderBlockPreview,
} from "../components/blocks/BblMagazineSliderBlock"
import {
  BblQuoteBlockFragment,
  BblQuoteBlockPreview,
} from "../components/blocks/BblQuoteBlock"
import {
  BblTextColumnBlockFragment,
  BblTextColumnBlockPreview,
} from "../components/blocks/BblTextColumnBlock"
import {
  BblTwoColumnWrapperBlockFragment,
  BblTwoColumnWrapperBlockPreview,
} from "../components/blocks/BblTwoColumnWrapperBlock"
import {
  BblTwoColumnWrapperInnerBlockFragment,
  BblTwoColumnWrapperInnerBlockPreview,
} from "../components/blocks/BblTwoColumnWrapperInnerBlock"

import {
  CoreHeadingBlockFragment,
  CoreHeadingBlockPreview,
} from "../components/blocks/CoreHeadingBlock"
import {
  CoreImageBlockFragment,
  CoreImageBlockPreview,
} from "../components/blocks/CoreImageBlock"
import {
  CoreListBlockFragment,
  CoreListBlockPreview,
} from "../components/blocks/CoreListBlock"
import {
  CoreParagraphBlockFragment,
  CoreParagraphBlockPreview,
} from "../components/blocks/CoreParagraphBlock/"
import {
  CoreSpacerBlockFragment,
  CoreSpacerBlockPreview,
} from "../components/blocks/CoreSpacerBlock"

import {
  GravityformsFormBlockFragment,
  GravityformsFormBlockPreview,
} from "../components/blocks/GravityformsFormBlock"
import { FormProvider } from "react-hook-form"

export const CoreFields = graphql`
  fragment CoreFields on WpBlock {
    name
    ...AcfArticleBannerBlock
    ...AcfBackgroundImageIntroBlock
    ...AcfBrewGalleryBlock
    ...AcfButtonBlock
    ...AcfCardLinkBlock
    ...AcfThreeColumnContentBlock
    ...AcfVideoBlock

    ...BblBackgroundTextBlock
    ...BblCalloutIntroBlock
    ...BblCardRowBlock
    ...BblFiftyFiftyFormBlock
    ...BblFiftyFiftyImagesBlock
    ...BblImageColumnBlock
    ...BblImageTextColumnsBlock
    ...BblImageTextStaggeredBlock
    ...BblIntroImageColumnsBlock
    ...BblIntroTextBlock
    ...BblMagazineSliderBlock
    ...BblOffsetImageBlock
    ...BblQuoteBlock
    ...BblTextColumnBlock
    ...BblTwoColumnWrapperBlock
    ...BblTwoColumnWrapperInnerBlock

    ...CoreHeadingBlock
    ...CoreImageBlock
    ...CoreListBlock
    ...CoreParagraphBlock
    ...CoreSpacerBlock

    ...GravityformsFormBlock
  }
`

export const PreviewBlocks = `
  ${AcfArticleBannerBlockPreview}
  ${AcfBackgroundImageIntroBlockPreview}
  ${AcfBrewGalleryBlockPreview}
  ${AcfButtonBlockPreview}
  ${AcfCardLinkBlockPreview}
  ${AcfThreeColumnContentBlockPreview}
  ${AcfVideoBlockPreview}

  ${BblBackgroundTextBlockPreview}
  ${BblCalloutIntroBlockPreview}
  ${BblCardRowBlockPreview}
  ${BblFiftyFiftyFormBlockPreview}
  ${BblFiftyFiftyImagesBlockPreview}
  ${BblImageColumnBlockPreview}
  ${BblImageTextColumnsBlockPreview}
  ${BblImageTextStaggeredBlockPreview}
  ${BblIntroImageColumnsBlockPreview}
  ${BblIntroTextBlockPreview}
  ${BblMagazineSliderBlockPreview}
  ${BblOffsetImageBlockPreview}
  ${BblQuoteBlockPreview}
  ${BblTextColumnBlockPreview}
  ${BblTwoColumnWrapperBlockPreview}
  ${BblTwoColumnWrapperInnerBlockPreview}

  ${CoreHeadingBlockPreview}
  ${CoreImageBlockPreview}
  ${CoreListBlockPreview}
  ${CoreParagraphBlockPreview}
  ${CoreSpacerBlockPreview}

  ${GravityformsFormBlockPreview}
  
  `
