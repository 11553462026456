import React from "react"
import { makeStyles } from "@material-ui/styles"
import styles from "./styles"
import Link from "../../Link"
import Icon from "./Icon"

const useStyles = makeStyles(theme => styles(theme))

export default function Card({
  className,
  link,
  background,
  heading,
  children,
}) {
  const classes = useStyles()

  if (link) {
    return (
      <Link
        className={`card-link ${className} ${classes.cardBlock}`}
        to={link}
        data-override-background={background}
      >
        <div className="min-height">{heading}</div>
        <Icon />
        {children}
      </Link>
    )
  }

  return (
    <div
      className={`card-link ${className} ${classes.cardBlock}`}
      data-override-background={background}
    >
      <div className="min-height">{heading}</div>
      <Icon />
      {children}
    </div>
  )
}
