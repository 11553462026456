export default theme => ({
  // eslint-disable-line
  quote: {
    padding: "40px 0",
    "& svg": {
      flex: "0 0 40px",
      marginRight: "auto",
      marginLeft: "calc(8.333% + 12px)",
      [theme.breakpoints.up("md")]: {
        flex: "0 0 93px",
      },
      "& .accent": {
        fill: "var(--page-accent)",
      },
      "& .shadow": {
        fill: "var(--uow-royal-blue)",
      },
    },
    "& p.MuiTypography-paragraph": {
      flex: "0 0 83.333%",
      padding: "0 12px",
      margin: "40px 8.333% 50px",
      fontWeight: "bold",
      fontFamily: "var(--uow-header-font)",
      fontSize: 32,
      lineHeight: "40px",
      letterSpacing: 0,
      [theme.breakpoints.up("md")]: {
        fontSize: 42,
        lineHeight: "65px",
      },
    },
  },
})
