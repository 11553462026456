export default theme => ({
  // eslint-disable-line
  offsetImage: {
    padding: "50px 0",
    [theme.breakpoints.up("md")]: {
      padding: "90px 0 80px",
    },
    "& .wrapper": {
      "& > .gatsby-image-wrapper, & > .gatsby-caption-wrapper": {
        maxWidth: "91.666%",
        flex: "0 0 91.666%",
        marginLeft: "auto",
        marginRight: -12,
        "& > div": {
          "&.caption": {
            maxWidth: 320,
          },
        },
        [theme.breakpoints.up(1344)]: {
          "& > div": {
            width: "calc(100% + (50vw - 692px))",
            maxWidth: "calc(100% + (50vw - 692px)) !important",
            "&.caption": {
              maxWidth: 320,
            },
          },
        },
        "&:before": {
          content: '""',
          display: "block",
          width: 60,
          height: 140,
          backgroundColor: "var(--page-accent)",
          top: -50,
          left: -24,
          position: "absolute",
          zIndex: 1,
          clipPath: "polygon(0 0, 100% 50px, 100% 100%, 0 calc(100% - 50px))",
          [theme.breakpoints.up("md")]: {
            width: 120,
            height: 280,
            top: -100,
            clipPath:
              "polygon(0 0, 100% 100px, 100% 100%, 0 calc(100% - 100px))",
          },
        },
      },
    },
    '&[data-photo-left="true"]': {
      "& .wrapper": {
        "& > .gatsby-image-wrapper, & > .gatsby-caption-wrapper": {
          marginLeft: -12,
          marginRight: "auto",
          [theme.breakpoints.up(1344)]: {
            marginLeft: "calc(0px - (50vw - 680px))",
          },
          "&:before": {
            left: "auto",
            right: -24,
            transform: "scaleX(-1)",
            [theme.breakpoints.up(1344)]: {
              right: "calc(0px - (50vw - 668px))",
            },
          },
        },
      },
    },
  },
})
