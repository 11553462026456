import React from "react"
import PropTypes from "prop-types"
import { AcfButtonBlockPreview } from "./queries"
import BrewButton from "../../Reusable/BrewButton"

export const AcfButtonBlock = ({ block }) => {
  const { buttonLink, backgroundColour } = block.ButtonFields

  if (block.ButtonFields.buttonLink === null) return null

  return (
    <BrewButton
      link={buttonLink?.url}
      background={backgroundColour}
      text={buttonLink.title}
    />
  )
}

AcfButtonBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export { AcfButtonBlockPreview }
