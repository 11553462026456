import React from "react"
import parse from "html-react-parser"
import PropTypes from "prop-types"
import { CoreListBlockPreview } from "./queries"

export const CoreListBlock = ({ attributes }) => {
  const { ordered, className, start, values } = attributes

  if (ordered === true) {
    return (
      <ol className={`${className} CoreList`} start={start !== 0 ? start : 1}>
        {parse(values !== null ? values : "")}
      </ol>
    )
  }

  return (
    <ul className={`${className} CoreList`}>
      {parse(values !== null ? values : "")}
    </ul>
  )
}

CoreListBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export { CoreListBlockPreview }
