export default theme => ({
  // eslint-disable-line
  twoColumnWrapper: {
    padding: "60px 0 36px",
    "& .wrapper": {
      justifyContent: "center",
      position: "relative",
      [theme.breakpoints.up("md")]: {
        "&:after": {
          content: '""',
          display: "block",
          left: "50%",
          top: 24,
          bottom: 48,
          backgroundColor: "var(--uow-soft-blue)",
          width: 1,
          position: "absolute",
        },
      },
    },
  },
})
