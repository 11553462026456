import { graphql } from "gatsby"

export const CoreImageBlockFragment = graphql`
  fragment CoreImageBlock on WpBlock {
    ... on WpCoreImageBlock {
      name
      originalContent
      attributes {
        __typename
        ... on WpCoreImageBlockAttributes {
          url
          alt
          className
          caption
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 1340
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                transformOptions: { fit: COVER, cropFocus: CENTER }
              )
            }
          }
        }
      }
    }
  }
`

export const CoreImageBlockPreview = `
  ... on CoreImageBlock {
    name
    originalContent
    attributes {
      __typename
      ... on CoreImageBlockAttributes {
        url
        className
        caption
      }
    }
  }`
