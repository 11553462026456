import React from "react"
import { BblBackgroundTextBlockPreview } from "./queries"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"
import Wrapper from "../../Reusable/Wrapper"

const useStyles = makeStyles(theme => styles(theme))

export const BblBackgroundTextBlock = ({
  attributes,
  innerBlocks,
  parentAttributes,
  pageContext,
}) => {
  const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext)
  const classes = useStyles()

  const { className } = attributes

  return (
    <div className={`background-text ${className} ${classes.backgroundText} `}>
      <Wrapper>
        <div className="background">
          {blocks.map(block => {
            return block
          })}
        </div>
      </Wrapper>
    </div>
  )
}

export { BblBackgroundTextBlockPreview }
