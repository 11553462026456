import React from "react"

import { buildBlocks, mergeBlocks } from "../../../services/builder"
import { AcfCardLinkBlockPreview } from "./queries"
import Card from "../../Reusable/Card"

export const AcfCardLinkBlock = ({
  innerBlocks,
  block,
  parentAttributes,
  pageContext,
  attributes,
}) => {
  const mergedBlocks = mergeBlocks(innerBlocks, block.innerImage)
  const blocks = buildBlocks(mergedBlocks, parentAttributes, pageContext)

  const { className } = attributes
  const { link, backgroundColour } = block.BlockCardLink

  return (
    <Card
      className={className}
      link={link && link.url}
      background={backgroundColour}
      heading={blocks[0]}
    >
      {blocks[1]}
    </Card>
  )
}

export { AcfCardLinkBlockPreview }
