export default theme => ({
  // eslint-disable-line
  backgroundImageIntro: {
    backgroundColor: "var(--uow-gray-blue)",
    marginLeft: -12,
    marginRight: -12,
    padding: "50px 0 350px",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      padding: "160px 0",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100vw",
      marginLeft: "50%",
      transform: "translateX(-50vw)",
      marginRight: 0,
    },
    "& .background": {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 1,
      "&:after": {
        position: "absolute",
        content: '""',
        height: 100,
        left: 0,
        right: 0,
        bottom: 300,
        background:
          "linear-gradient(180deg, rgba(91,106,134,1) 10%, rgba(91,106,134,0) 90%)",
        [theme.breakpoints.up("md")]: {
          left: "25%",
          right: "50%",
          bottom: 0,
          top: 0,
          height: "auto",
          background:
            "linear-gradient(90deg, rgba(91,106,134,1) 10%, rgba(91,106,134,0) 90%)",
        },
      },
      "& .image": {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        height: 400,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "var(--uow-gray-blue)",
        backgroundBlendMode: "luminosity",
        opacity: 0.3,
        [theme.breakpoints.up("md")]: {
          top: 0,
          right: 0,
          left: "auto",
          bottom: 0,
          height: "auto",
          width: "75%",
        },
      },
    },
    "& .con-wrap": {
      flex: "0 0 83.333%",
      width: "83.333%",
      padding: "0 12px",
      margin: "0 8.333%",
      position: "relative",
      zIndex: 10,
      [theme.breakpoints.up("md")]: {
        flex: "0 0 33.333%",
        width: "33.333%",
      },
      "& .MuiTypography-root": {
        color: "var(--uow-white)",
        fontWeight: "bold",
      },
      "& p.MuiTypography-root": {
        margin: "40px 0 24px",
        fontWeight: "normal",
      },
    },
  },
})
