import { graphql } from "gatsby"

export const BblTwoColumnWrapperInnerBlockFragment = graphql`
  fragment BblTwoColumnWrapperInnerBlock on WpBlock {
    ... on WpBblTwoColumnWrapperInnerBlock {
      name
      attributes {
        className
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblTwoColumnWrapperInnerBlockPreview = `
    ... on BblTwoColumnWrapperInnerBlock {
        name
        attributes {
            className
        }
    }
`
