import { graphql } from "gatsby"

export const AcfButtonBlockFragment = graphql`
  fragment AcfButtonBlock on WpBlock {
    ... on WpAcfButtonBlock {
      name
      attributes {
        className
      }
      ButtonFields {
        backgroundColour
        buttonLink {
          url
          title
          target
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfButtonBlockPreview = `
... on AcfButtonBlock {
  name
  attributes {
    className
  }
  ButtonFields {
    buttonStyle
    buttonLink {
      url
      title
      target
    }
  }
}
`
