import React from "react"

export default function IconEnd() {
  return (
    <svg x="0px" y="0px" viewBox="0 0 101 93">
      <path
        className="shadow"
        d="M90,40.2c0,8.9-2.4,18.4-7.1,28.3C78.6,77.7,72.6,86.1,65.2,93L45.7,82.5c3.5-6.2,6.3-12.7,8.2-19.6 c1.9-7.4,2.9-15,2.8-22.7V14H90V40.2z"
      />
      <path
        className="shadow"
        d="M44.3,40.2c0,8.9-2.4,18.4-7.1,28.3C33,77.7,26.9,86.1,19.5,93L0,82.5c3.5-6.2,6.3-12.7,8.2-19.6 c1.9-7.4,2.9-15,2.8-22.7V14h33.3V40.2z"
      />
      <path
        className="accent"
        d="M101,26.2c0,8.9-2.4,18.4-7.1,28.3C89.6,63.7,83.6,72.1,76.2,79L56.7,68.5c3.5-6.2,6.3-12.7,8.2-19.6 c1.9-7.4,2.9-15,2.8-22.7V0H101V26.2z"
      />
      <path
        className="accent"
        d="M55.3,26.2c0,8.9-2.4,18.4-7.1,28.3C44,63.7,38,72.1,30.5,79L11,68.5c3.5-6.2,6.3-12.7,8.2-19.6c1.9-7.4,2.9-15,2.8-22.7V0 h33.3V26.2z"
      />
    </svg>
  )
}
