import React from "react"
import { BblOffsetImageBlockPreview } from "./queries"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const BblOffsetImageBlock = ({
  attributes,
  innerBlocks,
  parentAttributes,
  pageContext,
}) => {
  const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext)
  const classes = useStyles()

  const { className, photoLeft } = attributes

  return (
    <div
      className={`offset-image ${className} ${classes.offsetImage} `}
      data-photo-left={photoLeft}
    >
      <div className="wrapper">
        {blocks.map(block => {
          return block
        })}
      </div>
    </div>
  )
}

export { BblOffsetImageBlockPreview }
