import React from "react"
import { BblQuoteBlockPreview } from "./queries"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"
import Wrapper from "../../Reusable/Wrapper"

import IconStart from "./IconStart"
import IconEnd from "./IconEnd"

const useStyles = makeStyles(theme => styles(theme))

export const BblQuoteBlock = ({
  attributes,
  innerBlocks,
  parentAttributes,
  pageContext,
}) => {
  const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext)
  const classes = useStyles()

  const { className } = attributes

  return (
    <div className={`quote ${className} ${classes.quote} `}>
      <Wrapper>
        <IconStart />
        {blocks.map(block => {
          return block
        })}
        <IconEnd />
      </Wrapper>
    </div>
  )
}

export { BblQuoteBlockPreview }
