import { graphql } from "gatsby"

export const BblBackgroundTextBlockFragment = graphql`
  fragment BblBackgroundTextBlock on WpBlock {
    ... on WpBblBackgroundTextBlock {
      name
      attributes {
        className
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblBackgroundTextBlockPreview = `
    ... on BblBackgroundTextBlock {
        name
        attributes {
            className
        }
    }
`
