export default theme => ({
  // eslint-disable-line
  imageTextStaggered: {
    paddingTop: 60,
    [theme.breakpoints.up("md")]: {
      marginBottom: -45,
    },
    "& .wrapper": {
      flexDirection: "row-reverse",
      alignItems: "flex-start",
      justifyContent: "center",
      "& > .gatsby-image-wrapper, & > .gatsby-caption-wrapper": {
        flex: "0 0 calc(91.666% - 24px)",
        margin: "0 0 32px 8.333%",
        maxWidth: "calc(91.666% - 24px)",
        position: "relative",
        [theme.breakpoints.up("md")]: {
          flex: "0 0 calc(41.666% - 24px)",
          marginLeft: "calc(8.333% + 24px)",
          maxWidth: "calc(41.666% - 24px)",
          marginTop: 360,
          marginBottom: 0,
        },
        "&:before": {
          content: '""',
          display: "block",
          width: 60,
          height: 140,
          backgroundColor: "var(--page-accent)",
          top: -50,
          left: -24,
          position: "absolute",
          zIndex: 1,
          clipPath: "polygon(0 0, 100% 50px, 100% 100%, 0 calc(100% - 50px))",
          [theme.breakpoints.up("md")]: {
            width: 120,
            height: 280,
            top: -100,
            clipPath:
              "polygon(0 0, 100% 100px, 100% 100%, 0 calc(100% - 100px))",
          },
        },
        "& > div": {
          width: "calc(100% + 24px)",
          maxWidth: "calc(100% + 24px)",
          [theme.breakpoints.up("md")]: {
            width: "calc(100% + 12px)",
            maxWidth: "calc(100% + 12px)",
          },
          [theme.breakpoints.up(1300)]: {
            width: "calc(100% + (50vw - 672px))",
            maxWidth: "calc(100% + (50vw - 672px))",
          },
          "&.caption": {
            width: "calc(100% - 115px)",
            maxWidth: "calc(100% - 115px)",
          },
        },
      },
      "& > .content": {
        flex: "0 0 83.333%",
        margin: "0 auto",
        maxWidth: "83.333%",
        padding: "0 12px",
        "& .MuiTypography-root": {
          marginBottom: 32,
        },
        [theme.breakpoints.up("md")]: {
          flex: "0 0 41.666%",
          marginLeft: "8.333%",
          maxWidth: "41.666%",
        },
      },
    },
    '&[data-photo-left="true"]': {
      "& .wrapper": {
        flexDirection: "row",
        "& > .gatsby-image-wrapper, & > .gatsby-caption-wrapper": {
          flex: "0 0 calc(91.666% - 24px)",
          margin: "0 8.333% 32px 0",
          maxWidth: "calc(91.666% - 24px)",
          [theme.breakpoints.up("md")]: {
            flex: "0 0 calc(41.666% - 24px)",
            marginRight: "calc(8.333% + 24px)",
            marginLeft: 0,
            maxWidth: "calc(41.666% - 24px)",
            marginTop: 360,
            marginBottom: 0,
          },
          "&:before": {
            left: "auto",
            right: -24,
            transform: "scaleX(-1)",
          },
          "& > div": {
            marginLeft: -24,
            [theme.breakpoints.up("md")]: {
              marginLeft: -12,
            },
            [theme.breakpoints.up(1300)]: {
              marginLeft: "calc(12px - (50vw - 672px))",
            },
            "&.caption": {
              width: "calc(100% - 115px)",
              maxWidth: "calc(100% - 115px)",
            },
          },
        },
        "& .content": {
          [theme.breakpoints.up("md")]: {
            marginLeft: 0,
            marginRight: "8.333%",
          },
        },
      },
    },
  },
})
