import { graphql } from "gatsby"

// NOTE: there is a type issue on the height field so using alias avoids the problem
export const CoreSpacerBlockFragment = graphql`
  fragment CoreSpacerBlock on WpBlock {
    ... on WpCoreSpacerBlock {
      name
      attributes {
        __typename
        ... on WpCoreSpacerBlockAttributes {
          heightPx: height
          className
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const CoreSpacerBlockPreview = `
... on CoreSpacerBlock {
    name
    attributes {
      __typename
      ... on CoreSpacerBlockAttributes {
        heightPx: height
        className
      }
    }
  }
`
