import { graphql } from "gatsby"

export const BblImageColumnBlockFragment = graphql`
  fragment BblImageColumnBlock on WpBlock {
    ... on WpBblImageColumnBlock {
      name
      attributes {
        className
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblImageColumnBlockPreview = `
    ... on BblImageColumnBlock {
        name
        attributes {
            className
        }
    }
`
