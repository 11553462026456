import { graphql } from "gatsby"

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CoreParagraphBlockFragment = graphql`
  fragment CoreParagraphBlock on WpBlock {
    ... on WpCoreParagraphBlock {
      name
      attributes {
        __typename
        ... on WpCoreParagraphBlockAttributes {
          backgroundColor
          textColor
          className
          align
          direction
          content
          fontSize
          dropCap
        }
      }
    }
  }
`

export const CoreParagraphBlockPreview = `
... on CoreParagraphBlock {
  name
  attributes {
    __typename
    ... on CoreParagraphBlockAttributes {
      backgroundColor
      textColor
      className
      align
      direction
      content
      fontSize
      dropCap
    }
  }
}
`
