import React from "react"
import parse from "html-react-parser"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import { transformLink } from "../../../helpers/"
import { CoreParagraphBlockPreview } from "./queries"

export const CoreParagraphBlock = ({ attributes }) => {
  const {
    content,
    align,
    className,
    fontSize,
    dropCap,
    textColor,
    backgroundColor,
  } = attributes

  if (content === "" || content === null) return null

  return (
    <Typography
      align={align !== "" ? align : "left"} // 	'center' | 'inherit' | 'justify' | 'left' | 'right' - default 'left'
      className={className}
      component="p" // HTML element type
      paragraph={true} // bool - default false
      variant={fontSize !== "" ? fontSize : "body1"}
      data-drop-cap={dropCap}
      data-override-color={textColor !== "" ? textColor : "initial"}
      data-override-background={
        backgroundColor !== "" ? backgroundColor : "initial"
      }
    >
      {parse(content !== null ? content : "", { transform: transformLink })}
    </Typography>
  )
}

CoreParagraphBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export { CoreParagraphBlockPreview }
