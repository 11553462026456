import React from "react"
import { BblIntroTextBlockPreview } from "./queries"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"
import Wrapper from "../../Reusable/Wrapper"

const useStyles = makeStyles(theme => styles(theme))

export const BblIntroTextBlock = ({
  attributes,
  innerBlocks,
  parentAttributes,
  pageContext,
}) => {
  const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext)
  const classes = useStyles()

  const { className } = attributes

  return (
    <div className={`intro-text ${className} ${classes.introText} `}>
      <Wrapper>
        {blocks.map(block => {
          return block
        })}
      </Wrapper>
    </div>
  )
}

export { BblIntroTextBlockPreview }
