import { graphql } from "gatsby"

export const AcfBackgroundImageIntroBlockFragment = graphql`
  fragment AcfBackgroundImageIntroBlock on WpBlock {
    ... on WpAcfBackgroundImageIntroBlock {
      name
      attributes {
        className
      }
      blockBackgroundImageIntro {
        backgroundImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfBackgroundImageIntroBlockPreview = `
    ... on AcfBackgroundImageIntroBlock {
        name
        attributes {
            className
        }
        blockBackgroundImageIntro {
          backgroundImage {
            sourceUrl
          }
        }
    }
}`
