export default theme => ({
  // eslint-disable-line
  threeColumnContent: {
    padding: "75px 0 43px",
    "& .MuiTypography-root, & .head-img": {
      margin: "0 auto 32px",
      flex: "0 0 calc(83.333% - 24px)",
    },
    "& .head-img": {
      position: "relative",
      "&:before": {
        content: '""',
        width: 0,
        height: 0,
        borderStyle: "solid",
        borderWidth: "33px 34px 0 34px",
        borderColor: "var(--page-accent) transparent transparent transparent",
        position: "absolute",
        top: -95,
        left: -34,
        [theme.breakpoints.up("md")]: {
          top: -184,
          left: -68,
          borderWidth: "66px 68px 0 68px",
        },
      },
    },
    [theme.breakpoints.up("md")]: {
      padding: "150px 0",
      "& > .wrapper": {
        justifyContent: "center",
        "& .MuiTypography-root, & .head-img": {
          margin: "0 3%",
          flex: "0 0 calc(25% - 24px)",
        },
        "& .head-img": {
          "& .MuiTypography-root": {
            margin: "0 0 32px",
          },
        },
      },
    },
  },
})
