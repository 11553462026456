export default theme => ({
  // eslint-disable-line
  introText: {
    boxShadow:
      "-300px 0 var(--uow-white), 300px 0 var(--uow-white), -600px 0 var(--uow-white), 600px 0 var(--uow-white)",
    position: "relative",
    backgroundColor: "var(--uow-white)",
    "& .MuiTypography-root": {
      width: "calc(83.333% - 24px)",
      margin: "0 auto 40px",
      [theme.breakpoints.up("md")]: {
        width: "calc(66.666% - 24px)",
        marginLeft: "calc(8.333% + 12px)",
      },
      [theme.breakpoints.up("lg")]: {
        width: "calc(50% - 24px)",
      },
    },
    "& p.MuiTypography-root": {
      marginBottom: 24,
      [theme.breakpoints.up("md")]: {
        marginBottom: 0,
      },
    },
  },
})
