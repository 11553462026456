import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { CoreFields } from "../../../templates/fragment" // eslint-disable-line
import { buildBlocks } from "../../../services/builder"

export const CoreBlock = props => {
  const data = useStaticQuery(graphql`
    query resuableBlocks {
      allWpReusableBlock {
        nodes {
          id
          blocks {
            ...CoreFields
            innerBlocks {
              ...CoreFields
              innerBlocks {
                ...CoreFields
                innerBlocks {
                  ...CoreFields
                  innerBlocks {
                    ...CoreFields
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  let reusableBlock = []

  if (props.block.reusableBlock.id !== null) {
    data.allWpReusableBlock.nodes.map(block => {
      // console.log(block);
      if (block.id === props.block.reusableBlock.id) {
        reusableBlock.push(block["blocks"][0])
      }
      return null
    })
  }

  // console.log(reusableBlock);

  const blocks = buildBlocks(reusableBlock, props.attributes, props.pageContext)
  // console.log(props);
  return (
    <>
      {blocks.map(block => {
        return block
      })}
    </>
  )
}

CoreBlock.propTypes = {
  attributes: PropTypes.object,
}

export const CoreBlockFragment = graphql`
  fragment CoreBlock on WpBlock {
    ... on WpCoreBlock {
      reusableBlock {
        __typename
        ... on WpReusableBlock {
          id
        }
      }
    }
  }
`

// This is queried direct in the preview page query
export const CoreBlockPreview = false
