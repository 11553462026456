import bg from "../../../images/alumni-bg.png"

const styles = theme => ({
  // eslint-disable-line
  fiftyfiftyForm: {
    position: "relative",
    backgroundColor: "var(--uow-royal-blue)",
    padding: "40px 0 80px",
    "&:before": {
      content: '""',
      display: "block",
      backgroundImage: "url(" + bg + ")",
      backgroundColor: "var(--uow-white)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      bottom: 0,
      left: "50%",
      width: "100vw",
      transform: "translateX(-50%)",
      height: 300,
      position: "absolute",
    },
    "& .life-grid": {
      content: '""',
      display: "block",
      position: "absolute",
      zIndex: -1,
      left: "50%",
      width: "100vw",
      transform: "translateX(-50%)",
      top: -100,
      bottom: 100,
      opacity: 0.33,
      mixBlendMode: "multiply",
      backgroundPosition: "center top",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      [theme.breakpoints.up("md")]: {
        top: -120,
      },
    },
    "& .MuiTypography-root": {
      position: "relative",
      marginBottom: 32,
      [theme.breakpoints.up("md")]: {
        marginBottom: 58,
      },
    },
    "& .columns": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      position: "relative",
      boxShadow: "0px 30px 60px rgba(0, 0, 0, 0.16)",
      flex: "0 0 100%",
      minWidth: "100%",
      "& .text": {
        backgroundColor: "var(--uow-white)",
        flex: "0 0 100%",
        minWidth: "100%",
        padding: "40px 0",
        [theme.breakpoints.up("md")]: {
          flex: "0 0 50%",
          minWidth: "50%",
          padding: "90px 0 120px",
        },
        "& > *": {
          width: "calc(83.333% - 24px)",
          marginLeft: "auto",
          marginRight: "auto",
          [theme.breakpoints.up("md")]: {
            width: "calc(66.666% - 24px)",
          },
        },
        "& h4": {
          marginBottom: 24,
        },
        "& p": {
          marginBottom: 32,
          "&.email": {
            backgroundImage:
              "url(https://res.cloudinary.com/cecdn/image/upload/v1649338660/Static/uow-blocks/email.png)",
            backgroundPosition: "top left",
            backgroundRepeat: "no-repeat",
            paddingLeft: 50,
            marginBottom: 24,
            lineHeight: 1.35,
            "& a": {
              fontWeight: "bold",
              textDecoration: "none",
            },
          },
          "&.address": {
            backgroundImage:
              "url(https://res.cloudinary.com/cecdn/image/upload/v1649338660/Static/uow-blocks/marker.png)",
            backgroundPosition: "top 4px left 4px",
            backgroundRepeat: "no-repeat",
            paddingLeft: 50,
            marginBottom: 24,
            lineHeight: "36px",
          },
        },
      },
      "& .form": {
        backgroundColor: "var(--uow-gray-blue)",
        flex: "0 0 100%",
        minWidth: "100%",
        padding: "40px 0",
        [theme.breakpoints.up("md")]: {
          flex: "0 0 50%",
          minWidth: "50%",
          padding: "90px 0 120px",
        },
        "& > *": {
          width: "calc(83.333% - 24px)",
          marginLeft: "auto",
          marginRight: "auto",
          [theme.breakpoints.up("md")]: {
            width: "calc(66.666% - 24px)",
          },
          "& label, & p, & h4": {
            color: "var(--uow-white) !important",
          },
        },
      },
    },
  },
})

export default styles
