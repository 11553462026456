export default theme => ({
  // eslint-disable-line
  brewGallery: {
    maxWidth: 1142,
    width: "100%",
    margin: "0 auto",
    "& .wp-block-gallery": {
      margin: 0,
      "& ul": {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        margin: 0,
        padding: 0,
        "& li": {
          padding: 12,
          margin: 0,
          display: "block",
          flex: "0 0 100%",
          maxWidth: "100%",
          fontSize: 0,
          [theme.breakpoints.up("sm")]: {
            flex: "0 0 50%",
            maxWidth: "50%",
          },
          "& figure": {
            margin: 0,
            "& span.gatsby-image-wrapper": {
              "& > span": {
                paddingBottom: "100% !important",
              },
            },
          },
        },
      },
      "&.columns-3": {
        "& ul li": {
          [theme.breakpoints.up("md")]: {
            flex: "0 0 33.333%",
            maxWidth: "33.333%",
          },
        },
      },
      "&.columns-4": {
        "& ul li": {
          [theme.breakpoints.up("md")]: {
            flex: "0 0 25%",
            maxWidth: "25%",
          },
        },
      },
      "&.columns-5": {
        "& ul li": {
          [theme.breakpoints.up("md")]: {
            flex: "0 0 25%",
            maxWidth: "25%",
          },
          [theme.breakpoints.up("lg")]: {
            flex: "0 0 20%",
            maxWidth: "20%",
          },
        },
      },
    },
  },
})
