export default theme => ({
  // eslint-disable-line
  imageTextColumns: {
    "& .wrapper": {
      flexDirection: "row-reverse",
      alignItems: "flex-end",
      justifyContent: "center",
      "& > .gatsby-image-wrapper, & > .gatsby-caption-wrapper": {
        flex: "0 0 calc(83.333% - 24px)",
        margin: "0 auto 32px",
        maxWidth: "calc(83.333% - 24px)",
        [theme.breakpoints.up("md")]: {
          flex: "0 0 41.666% - 24px)",
          margin: "0 12px",
          maxWidth: "calc(41.666% - 24px)",
        },
      },
      "& > .gatsby-caption-wrapper": {
        "& .caption": {
          paddingRight: 70,
          "&:before": {
            content: '""',
            backgroundColor: "var(--page-accent)",
            position: "absolute",
            width: 120,
            height: 94,
            top: 0,
            right: -60,
            clipPath: "polygon(50% 0, 100% 50%, 50% 100%, 0 50%)",
          },
        },
      },
      "& > .MuiTypography-paragraph": {
        flex: "0 0 calc(83.333% - 24px)",
        margin: "0 auto",
        maxWidth: "calc(83.333% - 24px)",
        [theme.breakpoints.up("md")]: {
          flex: "0 0 41.666% - 64px)",
          margin: "0 52px 0 12px",
          maxWidth: "calc(41.666% - 64px)",
        },
      },
    },
    '&[data-photo-left="true"]': {
      "& .wrapper": {
        flexDirection: "row",
        "& > .MuiTypography-paragraph": {
          flex: "0 0 calc(83.333% - 24px)",
          [theme.breakpoints.up("md")]: {
            margin: "0 12px 0 52px",
          },
        },
      },
    },
    '&[data-text-top="true"]': {
      "& .wrapper": {
        alignItems: "flex-start",
      },
    },
  },
})
