import React from "react"
import { BblFiftyFiftyImagesBlockPreview } from "./queries"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"
import Wrapper from "../../Reusable/Wrapper"

const useStyles = makeStyles(theme => styles(theme))

export const BblFiftyFiftyImagesBlock = ({
  attributes,
  innerBlocks,
  parentAttributes,
  pageContext,
}) => {
  const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext)
  const classes = useStyles()

  const { className, alignLeft, alignBottom } = attributes

  return (
    <div
      className={`fifty-fifty-images ${className} ${classes.fiftyFiftyImages} `}
      data-left={alignLeft}
      data-bottom={alignBottom}
    >
      <Wrapper>
        {blocks.map(block => {
          return block
        })}
      </Wrapper>
    </div>
  )
}

export { BblFiftyFiftyImagesBlockPreview }
