export default theme => ({
  // eslint-disable-line
  backgroundText: {
    padding: "50px 0",
    [theme.breakpoints.up("md")]: {
      padding: "100px 0",
    },
    "& .background": {
      backgroundColor: "var(--uow-gray-blue)",
      display: "block",
      width: "calc(83.333% - 24px)",
      margin: "0 auto",
      position: "relative",
      padding: "40px calc((100% + 24px) / 10)",
      [theme.breakpoints.up("md")]: {
        paddingTop: 80,
        paddingBottom: 80,
      },
      "&:before": {
        content: '""',
        width: 0,
        height: 0,
        borderStyle: "solid",
        borderWidth: "33px 34px 0 34px",
        borderColor: "var(--page-accent) transparent transparent transparent",
        position: "absolute",
        top: -20,
        left: -34,
        [theme.breakpoints.up("md")]: {
          top: -34,
          left: -68,
          borderWidth: "66px 68px 0 68px",
        },
      },
      "& .MuiTypography-paragraph": {
        color: "var(--uow-white)",
        fontFamily: "var(--uow-header-font)",
        fontWeight: "bold",
        fontSize: 24,
        letterSpacing: "0.008em",
        lineHeight: "36px",
        [theme.breakpoints.up("md")]: {
          fontSize: 32,
          lineHeight: "48px",
        },
      },
    },
  },
})
