import React from "react"

import { AcfArticleBannerBlock } from "../components/blocks/AcfArticleBannerBlock"
import { AcfBackgroundImageIntroBlock } from "../components/blocks/AcfBackgroundImageIntroBlock"
import { AcfBrewGalleryBlock } from "../components/blocks/AcfBrewGalleryBlock"
import { AcfButtonBlock } from "../components/blocks/AcfButtonBlock"
import { AcfCardLinkBlock } from "../components/blocks/AcfCardLinkBlock"
import { AcfThreeColumnContentBlock } from "../components/blocks/AcfThreeColumnContentBlock"
import { AcfVideoBlock } from "../components/blocks/AcfVideoBlock"

import { BblBackgroundTextBlock } from "../components/blocks/BblBackgroundTextBlock"
import { BblCalloutIntroBlock } from "../components/blocks/BblCalloutIntroBlock"
import { BblCardRowBlock } from "../components/blocks/BblCardRowBlock"
import { BblFiftyFiftyFormBlock } from "../components/blocks/BblFiftyFiftyFormBlock"
import { BblFiftyFiftyImagesBlock } from "../components/blocks/BblFiftyFiftyImagesBlock"
import { BblImageColumnBlock } from "../components/blocks/BblImageColumnBlock"
import { BblImageTextColumnsBlock } from "../components/blocks/BblImageTextColumnsBlock"
import { BblImageTextStaggeredBlock } from "../components/blocks/BblImageTextStaggeredBlock"
import { BblIntroImageColumnsBlock } from "../components/blocks/BblIntroImageColumnsBlock"
import { BblIntroTextBlock } from "../components/blocks/BblIntroTextBlock"
import { BblMagazineSliderBlock } from "../components/blocks/BblMagazineSliderBlock"
import { BblOffsetImageBlock } from "../components/blocks/BblOffsetImageBlock"
import { BblQuoteBlock } from "../components/blocks/BblQuoteBlock"
import { BblTextColumnBlock } from "../components/blocks/BblTextColumnBlock"
import { BblTwoColumnWrapperBlock } from "../components/blocks/BblTwoColumnWrapperBlock"
import { BblTwoColumnWrapperInnerBlock } from "../components/blocks/BblTwoColumnWrapperInnerBlock"

import { CoreBlock } from "../components/blocks/CoreBlock"
import { CoreHeadingBlock } from "../components/blocks/CoreHeadingBlock/"
import { CoreImageBlock } from "../components/blocks/CoreImageBlock/"
import { CoreListBlock } from "../components/blocks/CoreListBlock/"
import { CoreParagraphBlock } from "../components/blocks/CoreParagraphBlock/"
import { CoreSpacerBlock } from "../components/blocks/CoreSpacerBlock/"

import { GravityformsFormBlock } from "../components/blocks/GravityformsFormBlock"

/**
 * Returns the component for the block name
 * @param {String} name
 */
function mapBlocks(name) {
  let block = null
  switch (name) {
    case "AcfArticleBannerBlock":
      block = AcfArticleBannerBlock
      break
    case "AcfBackgroundImageIntroBlock":
      block = AcfBackgroundImageIntroBlock
      break
    case "AcfBrewGalleryBlock":
      block = AcfBrewGalleryBlock
      break
    case "AcfButtonBlock":
      block = AcfButtonBlock
      break
    case "AcfCardLinkBlock":
      block = AcfCardLinkBlock
      break
    case "AcfThreeColumnContentBlock":
      block = AcfThreeColumnContentBlock
      break
    case "AcfVideoBlock":
      block = AcfVideoBlock
      break

    case "BblBackgroundTextBlock":
      block = BblBackgroundTextBlock
      break
    case "BblCalloutIntroBlock":
      block = BblCalloutIntroBlock
      break
    case "BblCardRowBlock":
      block = BblCardRowBlock
      break
    case "BblImageColumnBlock":
      block = BblImageColumnBlock
      break
    case "BblFiftyFiftyFormBlock":
      block = BblFiftyFiftyFormBlock
      break
    case "BblFiftyFiftyImagesBlock":
      block = BblFiftyFiftyImagesBlock
      break
    case "BblImageTextColumnsBlock":
      block = BblImageTextColumnsBlock
      break
    case "BblIntroImageColumnsBlock":
      block = BblIntroImageColumnsBlock
      break
    case "BblImageTextStaggeredBlock":
      block = BblImageTextStaggeredBlock
      break
    case "BblMagazineSliderBlock":
      block = BblMagazineSliderBlock
      break
    case "BblIntroTextBlock":
      block = BblIntroTextBlock
      break
    case "BblOffsetImageBlock":
      block = BblOffsetImageBlock
      break
    case "BblQuoteBlock":
      block = BblQuoteBlock
      break
    case "BblTextColumnBlock":
      block = BblTextColumnBlock
      break
    case "BblTwoColumnWrapperBlock":
      block = BblTwoColumnWrapperBlock
      break
    case "BblTwoColumnWrapperInnerBlock":
      block = BblTwoColumnWrapperInnerBlock
      break

    case "CoreBlock":
      block = CoreBlock
      break
    case "CoreHeadingBlock":
      block = CoreHeadingBlock
      break
    case "CoreImageBlock":
      block = CoreImageBlock
      break
    case "CoreListBlock":
      block = CoreListBlock
      break
    case "CoreParagraphBlock":
      block = CoreParagraphBlock
      break
    case "CoreSpacerBlock":
      block = CoreSpacerBlock
      break

    case "GravityformsFormBlock":
      block = GravityformsFormBlock
      break

    default:
      block = null
      break
  }
  return block
}

function UCFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Converts the block name in to a component name
 * eg. core/banner = CoreBannerBlock
 * @param {String} name
 */
function convertName(name) {
  const split = name.split("/")
  const parts = []
  parts.push(UCFirst(split[0]))
  const split2 = split[1].split("-")
  split2.map(part => {
    return parts.push(UCFirst(part))
  })
  return parts.join("") + (name === "core/block" ? "" : "Block")
}

export const buildBlocks = (
  blocks,
  parentAttributes = {},
  pageContext = {}
) => {
  if (!blocks) return []
  // Render the blocks
  let processed = []
  blocks.map((block, index) => {
    if (!block.name) return false
    let elem = null

    // Convert the block name to a component name
    const component = convertName(block.name)
    let OutputBlock = null

    OutputBlock = mapBlocks(component)

    elem = OutputBlock ? (
      <OutputBlock
        key={index}
        attributes={block.attributes}
        parentAttributes={parentAttributes}
        originalContent={block.originalContent}
        innerBlocks={
          component === "CoreBlock"
            ? block.reusableBlock.blocks
            : block.innerBlocks
        }
        pageContext={pageContext}
        block={block}
      />
    ) : null

    return processed.push(elem)
  })
  return processed
}

export const mergeBlocks = (coreBlocks, customBlocks) => {
  let blocks = coreBlocks
  customBlocks.map((block, index) => {
    if (block.name) {
      blocks[index] = block
    }
    return null
  })
  return blocks
}
