export default theme => ({
  // eslint-disable-line
  imageColumn: {
    flex: "0 0 83.33%",
    margin: "0 auto 24px",
    padding: "0 12px",
    [theme.breakpoints.up("md")]: {
      flex: "0 0 calc(25% - 24px)",
      margin: "0 3%",
      padding: 0,
      "&:nth-of-type(1)": {
        marginTop: 300,
      },
      "&:nth-of-type(2)": {
        marginTop: 150,
      },
    },
    "& .MuiTypography-root": {
      position: "relative",
      zIndex: 2,
      marginBottom: -8,
      [theme.breakpoints.up("md")]: {
        marginBottom: -16,
      },
    },
    "& .gatsby-caption-wrapper": {
      position: "relative",
      zIndex: 1,
      boxShadow: "0px 12px 32px rgba(24,44,68,.28)",
    },
  },
})
