const styles = theme => ({
  cardBlock: {
    cursor: "pointer",
    padding: "36px 30px 28px 0",
    backgroundColor: "var(--uow-orange-yellow)",
    boxShadow: "-16px 24px 32px rgba(24,44,68,.28)",
    textDecoration: "none !important",
    "& .min-height": {
      height: 70,
      transition: "padding-top .3s ease-in-out",
      "& .MuiTypography-root": {
        paddingLeft: 45,
      },
    },
    "& svg": {
      marginBottom: 52,
      marginLeft: 45,
      marginRight: 45,
      transition: "margin-left .3s ease-in-out",
      "& *": {
        fill: "var(--uow-royal-blue)",
      },
    },
    "&:hover, &:focus": {
      "& .min-height": {
        paddingTop: 6,
      },
      "& svg": {
        marginLeft: "calc(100% - 200px)",
      },
    },
  },
})

export default styles
